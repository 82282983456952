import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

import { type AxiosError } from 'axios'
import { type ChemicalSeedTransaction } from '../types'

export const chemicalSeedReport = async (
  merchant_id: number | null | undefined
): Promise<ApiResponse<[ChemicalSeedTransaction]>> => {
  return axios.get(`/voucher/redemption?merchant_id=${merchant_id ?? ''}`)
}

export const useGetChemicalSeedReport = (
  merchant_id: number | null | undefined
): UseQueryResult<ApiResponse<ChemicalSeedTransaction>, AxiosError> => {
  return useQuery({
    queryKey: ['chemical-seed-dealer-reports', merchant_id],
    queryFn: async () => chemicalSeedReport(merchant_id),
    enabled: merchant_id != null
  })
}
