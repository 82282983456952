import { Footer, NavLandingPage } from 'features/landing/components'
import type React from 'react'
import { useEffect } from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from '../../../components/ui/spinner'
import { clearLoginAttemptInfo } from 'supertokens-web-js/lib/build/recipe/passwordless'
import { HeaderHome } from '../components/SubComponents/Home/HeaderHome'
import { WhatIsTemo } from '../components/SubComponents/Home/WhatIsTemo'
import { EmphasisSection } from '../components/SubComponents/Home/EmphasisSection'
import { Partners } from '../components/SubComponents/About/Partners'
import { TestimonialsFarmers } from '../components/SubComponents/Generic/Testimonials'
import { FooterCTA } from '../components/SubComponents/About/FooterCTA'
import { TemoServices } from '../components/SubComponents/Home/TemoServices'
import { TemoBenefits } from '../components/SubComponents/Home/TemoBenefits'
import FooterImage from 'assets/landing/swiper/20230119_6/20230119_4.jpg'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

/**
 * React functional component to display the Homepage.
 *
 * @returns {JSX.Element} The JSX code to render the Homepage.
 */
export const Homepage: React.FC = () => {
  const { t } = useTranslation('landing')
  const flags = useFlags()
  const ldClient = useLDClient()
  const claimValue = Session.useClaimValue(UserRoleClaim)

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  const roles = claimValue.value

  /**
   * Function to handle navigation to the appropriate dashboard based on user roles.
   *
   * @returns {string} The path to navigate to the appropriate dashboard.
   */
  const handleNavigateToDashboard = (): string => {
    if (Array.isArray(roles) && roles.includes('FARMER')) {
      return '/farmer'
    } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
      return '/officer'
    } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
      return '/soil-lab'
    } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
      return '/inventory'
    } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
      return '/seed-dealer'
    } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
      return '/service-provider'
    } else if (Array.isArray(roles) && roles.includes('AGRO_CHEMICAL_DEALER')) {
      return '/agrochemicals'
    } else if (Array.isArray(roles) && roles.includes('CHEMICAL_SEED_DEALER')) {
      return '/chemical-seed-dealer'
    } else {
      void clearLoginAttemptInfo()
      return '/login/phone'
    }
  }

  return (
    <main className='landing-page'>
      {/* SEO Optimization */}
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Temo Letlotlo - Empowering Botswana Farmers</title>
        <meta
          name='description'
          content='Temo Letlotlo provides tools, loans, and support to farmers across Botswana. Helping grain producers with technical assistance and financial solutions.'
        />
        <meta
          name='keywords'
          content='Crop insurance programs for Botswana farmers, Thuo Letlotlo, BAMB, BIUST, Botswana farming, Financial assistance for Botswana grain producers, Best farming tools for smallholder farmers in Botswana, Digital platform for Botswana farmers, agricultural loans, crop insurance, farmer support, grain production, Weather-based crop insurance, Supervised credit program for farmers, Farming tools and resources Botswana, Agricultural productivity Botswana, Botswana farmer credit support, How to get farming loans in Botswana'
        />
        <meta name='author' content='Temo Letlotlo' />

        {/* Open Graph / Facebook */}
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Temo Letlotlo - Empowering Botswana Farmers' />
        <meta
          property='og:description'
          content="Providing Botswana's farmers with the tools and resources to succeed through agricultural loans, insurance, and technical assistance."
        />
        <meta property='og:image' content='https://www.temoletlotlo.com/og-image.jpg' />
        <meta property='og:url' content='https://www.temoletlotlo.com' />

        {/* Twitter */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Temo Letlotlo - Empowering Botswana Farmers' />
        <meta
          name='twitter:description'
          content="Supporting Botswana's farmers through agricultural loans and resources."
        />
        <meta name='twitter:image' content='https://www.temoletlotlo.com/twitter-image.jpg' />
      </Helmet>

      {/* Page Components */}

      <NavLandingPage
        goToDashboard={handleNavigateToDashboard}
        isAuthenticated={claimValue.value != null}
      />
      <HeaderHome />
      <WhatIsTemo />
      <EmphasisSection />
      <Partners header={t('home.supp')} />
      <TemoServices />
      <TemoBenefits />
      <TestimonialsFarmers bgColor='bg-white' />
      <FooterCTA
        backgroundImage={FooterImage}
        headerText={t('home.read')}
        buttonText={t('home.reg')}
        navigationLink='/registration'
      />
      <Footer />
    </main>
  )
}
