import { FormControlLabel } from '@mui/material'
import { Text } from 'components/Elements/Text/Text'
import { useAtom } from 'jotai'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import type z from 'zod'
import { coerce, object } from 'zod'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { type CroppingPlan } from '../../../../../types'
import { useGetFarms } from '../../../../farmer/land-management/api/getFarms'
import { useGetCroppingPlans } from '../../../api/getCroppingPlans'
import { type CroppingPlanId } from '../../../types/individual'
import { classifyHectare } from '../../../utils/farmerClassification'
import { companyLoanStore, farmerType } from '../stores'
import { type LoanStepperProps } from '../../../types'
import {
  Card,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  useStepper
} from '../../../../../components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '../../../../../components/App/Layout'
import { BadgeCheck, Info } from 'lucide-react'
import { FormattedNumber, IntlProvider } from 'react-intl'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '../../../../../components/ui/tooltip'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../../../../../components/ui/checkbox'
import { usePloughSeason } from '../../../../../hooks/usePloughSeason'

export const SelectCompanyPlans: React.FC<LoanStepperProps> = ({ cancel, onComplete }) => {
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')
  const { farmer } = useFarmerStore()
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const [, setEnableQuery] = useState(false)
  const { data: farms, isLoading } = useGetFarms(farmer?.farmer_id)
  const { data: croppingPlans } = useGetCroppingPlans(farmer?.farmer_id as number)
  const [selectedFarmIds, setSelectedFarmIds] = useState<number[]>([])
  const [selectedCroppingPlans, setSelectedCroppingPlans] = useState<CroppingPlanId[]>([])
  const [calculatedTotalAmount, setCalculatedTotalAmount] = useState<number | null>(null)
  const [totalHectrage, setTotalHectrage] = useState<number | null>(null)
  const [famerType, setFarmerType] = useAtom(farmerType)
  const { isCurrentSeason } = usePloughSeason()
  const calculateTotalSize = (): void => {
    if (selectedCroppingPlans.length === 0) {
      setTotalHectrage(null)
      setFarmerType((prev) => ({
        ...prev,
        farmerType: classifyHectare(0)
      }))
    } else {
      const totalSizeAmount = selectedCroppingPlans.reduce((total, croppingPlanId) => {
        const selectedCroppingPlan = croppingPlans?.data.find(
          (plan: { cropping_plan_id: number }) =>
            plan.cropping_plan_id === (croppingPlanId as unknown as number)
        )
        if (selectedCroppingPlan != null) {
          total += selectedCroppingPlan.hectarage
        }
        return total
      }, 0)

      setTotalHectrage(totalSizeAmount)
      setFarmerType((prev) => ({
        ...prev,
        farmerType: classifyHectare(totalSizeAmount)
      }))
    }
  }

  const calculateTotalAmount = (): void => {
    if (selectedCroppingPlans.length === 0) {
      setCalculatedTotalAmount(null)
    } else {
      const totalAmount = selectedCroppingPlans.reduce((total, croppingPlanId) => {
        const selectedCroppingPlan = croppingPlans?.data.find(
          (plan: { cropping_plan_id: number }) =>
            plan.cropping_plan_id === (croppingPlanId as unknown as number)
        )
        if (selectedCroppingPlan != null) {
          total += parseFloat(selectedCroppingPlan.total_cost)
        }
        return total
      }, 0)
      setCalculatedTotalAmount(totalAmount)
    }
  }

  const schema = object({
    loanAmount: coerce
      .number({
        invalid_type_error: 'This should be a number'
      })
      .refine(
        (value) => {
          return calculatedTotalAmount !== null && value <= calculatedTotalAmount
        },
        {
          message: `Loan amount must be less than or equal to ${
            calculatedTotalAmount !== null
              ? `BWP ` + calculatedTotalAmount.toString() + `.00`
              : 'given amount'
          }`
        }
      )
  })

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      loanAmount: loanData?.total_amount.loan_req1
    }
  })

  useEffect(() => {
    if (farmer?.farmer_id !== undefined && farms === undefined) {
      setEnableQuery(true)
    }
    calculateTotalAmount()
    calculateTotalSize()
  }, [farms, farmer, selectedCroppingPlans])

  const handleCroppingPlanCheckboxClick = (croppingPlanId: number): void => {
    setSelectedCroppingPlans((prevSelectedCroppingPlans) => {
      const idToToggle = croppingPlanId as unknown as CroppingPlanId
      if (prevSelectedCroppingPlans.includes(idToToggle)) {
        return prevSelectedCroppingPlans.filter((id) => id !== idToToggle)
      } else {
        return [...prevSelectedCroppingPlans, idToToggle]
      }
    })
  }

  const mapCroppingPlansToObjects = (): CroppingPlanId[] => {
    return selectedCroppingPlans
      .map((croppingPlanId) => {
        const selectedCroppingPlan = croppingPlans?.data.find(
          (plan: { cropping_plan_id: number }) =>
            plan.cropping_plan_id === (croppingPlanId as unknown as number)
        )
        return selectedCroppingPlan != null
          ? {
              cropping_plan_id: croppingPlanId
            }
          : null
      })
      .filter(Boolean) as unknown as CroppingPlanId[]
  }

  const areCroppingPlansSelected = (): boolean => {
    return selectedCroppingPlans.length > 0
  }
  const hasApprovedCroppingPlan = (farmId: number): boolean => {
    return (
      croppingPlans?.data?.some(
        (plan: CroppingPlan) =>
          plan.is_verified === 'Approved' &&
          plan.farm_id === farmId &&
          isCurrentSeason(plan.plough_season)
      ) ?? false
    )
  }

  const onSubmit = (data: z.infer<typeof schema>): void => {
    setLoanData((prev) => ({
      ...prev!,
      cropping_plan: mapCroppingPlansToObjects() as unknown as CroppingPlanId[],
      total_amount: {
        loan_req1: data?.loanAmount
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col w-full'}>
          <div className='space-y-8 my-6'>
            {!isLoading && farms?.data != null && farms.data.length > 0 && (
              <div className='flex flex-col gap-2'>
                <Text size='medium' className=''>
                  {t('plans.plans')}:
                </Text>

                <div className='flex'>
                  <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                  <Text variant='secondary' size='small' className=''>
                    {t('plans.one')}
                  </Text>
                </div>
              </div>
            )}

            {farms?.data
              .filter((item) => item.status === 'verified' && hasApprovedCroppingPlan(item.farm_id))
              .map((item) => {
                const isSelected = selectedFarmIds.includes(item.farm_id)
                return (
                  <Card
                    key={item.farm_id}
                    className='px-4 py-8 my-2 transition-transform transform hover:scale-[1.01] hover:shadow-lg'
                  >
                    <FormControlLabel
                      sx={{
                        position: 'relative',
                        padding: '0 1rem',
                        display: 'flex',
                        alignItems: 'center',
                        height: '0.5rem'
                      }}
                      control={
                        <Checkbox
                          hidden
                          checked={isSelected}
                          onCheckedChange={() => {
                            if (isSelected) {
                              setSelectedFarmIds(
                                selectedFarmIds.filter((id) => id !== item.farm_id)
                              )
                            } else {
                              setSelectedFarmIds([...selectedFarmIds, item.farm_id])
                            }
                          }}
                          value={item.farm_id.toString()}
                        />
                      }
                      label={
                        <div className='flex'>
                          <Text size='body' className='mr-2'>
                            {`${item.farm_name.toUpperCase()} FARM`}
                          </Text>
                          {item.status === 'verified' && (
                            <BadgeCheck size='16' className='text-blue-500' />
                          )}
                        </div>
                      }
                    />
                    {isSelected && croppingPlans == null && (
                      <Text className='m-8'>{t('plans.select')}</Text>
                    )}
                    {isSelected &&
                      croppingPlans?.data !== undefined &&
                      croppingPlans?.data !== null &&
                      croppingPlans?.data
                        .filter((i) => i.is_verified === 'Approved' && i.farm_id === item.farm_id)
                        .map((option) => (
                          <div
                            className='w-full sm:w-11/12 flex border-t-2 border-solid border-gray-300 mx-auto px-4 mt-3 mb-3 hover:underline'
                            key={option.cropping_plan_id}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className='mt-2.5'
                                  checked={selectedCroppingPlans.includes(
                                    option.cropping_plan_id as unknown as CroppingPlanId
                                  )}
                                  onCheckedChange={() => {
                                    handleCroppingPlanCheckboxClick(option.cropping_plan_id)
                                  }}
                                  value={option.cropping_plan_id.toString()}
                                />
                              }
                              label={
                                <Text size='small' className='ml-5 mt-2.5'>
                                  {`${option.cropping_plan_id} - ${option.crop_name} - ${option.hectarage} ha`}
                                </Text>
                              }
                            />
                          </div>
                        ))}
                  </Card>
                )
              })}

            {isLoading && farms === undefined && (
              <Text size='body' className=''>
                {t('plans.wait')}
              </Text>
            )}

            {!isLoading && farms?.data.length === 0 && (
              <Text variant='bodyTextLight' size='medium' className='ml-4 sm:ml-8 mb-4 sm:mb-8'>
                {t('plans.none')}.
              </Text>
            )}

            {!isLoading &&
              (farms?.data?.length ?? 0) > 0 &&
              selectedFarmIds.length > 0 &&
              !areCroppingPlansSelected() && (
                <Text size='body' className=''>
                  {t('plans.select')}
                </Text>
              )}

            {areCroppingPlansSelected() ? (
              <div className='max-w-full md:max-w-[400px] flex flex-col gap-4 mb-5'>
                <FormField
                  control={form.control}
                  name='loanAmount'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='medium' className=''>
                          {t('plans.amount')} (BWP)
                        </Text>
                      </FormLabel>
                      <FormDescription>
                        <IntlProvider locale='en' defaultLocale='en'>
                          <div className='flex my-2'>
                            <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                            <Text variant='secondary' size='small' className=''>
                              {t('plans.max')}:{' '}
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <span className='text-red-400 font-bold'>
                                      <FormattedNumber
                                        value={calculatedTotalAmount ?? 0}
                                        style='currency'
                                        currency='BWP'
                                      />
                                    </span>
                                  </TooltipTrigger>

                                  <TooltipContent>
                                    <p>
                                      This amount is calculated based on the total cost of your
                                      selected cropping plans.
                                    </p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </Text>
                          </div>
                        </IntlProvider>
                      </FormDescription>
                      <FormControl>
                        <div className='relative'>
                          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                            <Text size='bodyBold' variant='bodyTextLight' className=''>
                              BWP
                            </Text>
                          </div>
                          <Input
                            id='currency'
                            inputMode='decimal'
                            type='number'
                            min={1}
                            max={calculatedTotalAmount !== null ? calculatedTotalAmount : undefined}
                            step={0.01}
                            placeholder='0.00'
                            className='pl-14'
                            {...field}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ) : (
              <div className='flex'>
                <Info size='18' color='red' className='mr-2.5' />
                <Text variant='error' size='small' className=''>
                  {t('plans.one')}
                </Text>
              </div>
            )}
          </div>

          <LoanStepperFooter isFirstStep cancel={cancel} />
        </form>
      </Form>
    </Box>
  )
}
