import {
  type CroppingPlanId,
  type ImmovablePropertySecurity,
  type Loan,
  type OtherLoansDetails,
  type ProductionDetails
} from '../types/individual'
import { useMutation } from '@tanstack/react-query'
import { createLoan } from '../api/createLoan'
import { useAtom } from 'jotai/index'
import { individualLoanItem } from '../components/stepper/stores/individualLoanItem'
import { AxiosError, type AxiosResponse } from 'axios'
import type { ApiResponse } from '../../../types'
import { mapCmsErrorToMessage } from '../../../utils/apiErrors'
import { useFarmerStore } from '../../../stores/useFarmerStore'
import { applicationHeader } from '../components/stepper/stores/applicationHeader'
import { toast } from '../../../components/ui'

interface UseIndividualLoan {
  isLoading: boolean
  sendToCMS: (next: () => void, onComplete?: (status: boolean) => void) => void
}

export const useIndividualLoan = (): UseIndividualLoan => {
  const [loanData] = useAtom(individualLoanItem)
  const { farmer } = useFarmerStore()
  const mutation = useMutation({
    mutationFn: async (params: Loan) => {
      return createLoan(params)
    }
  })
  const [, setApplicationHead] = useAtom(applicationHeader)

  const sendToCMS = (next: () => void, onComplete?: (status: boolean) => void): void => {
    mutation.mutate(
      {
        cropping_plan: loanData?.cropping_plan as CroppingPlanId[],
        total_amount: {
          loan_req1: Number(loanData?.total_amount?.loan_req1)
        },
        individual_loan: {
          farmer_id: Number(farmer?.farmer_id),
          loan_req2: Number(loanData?.individual_loan?.loan_req2),
          loan_req3: loanData?.individual_loan?.loan_req3 ?? '',
          loan_req4: loanData?.individual_loan?.loan_req4 ?? '',
          period_at_physical_addr: Number(loanData?.individual_loan?.period_at_physical_addr),
          period_at_prev_physical_addr: Number(
            loanData?.individual_loan?.period_at_prev_physical_addr
          ),
          ownership_at_physical_addr: loanData?.individual_loan?.ownership_at_physical_addr ?? '',
          source_reference: loanData?.individual_loan?.source_reference ?? '',
          title_id: loanData?.individual_loan?.title_id ?? '',
          last_update_by: Number(farmer?.user_id)
        },

        employee_information: {
          applicant_employer: loanData?.employee_information?.applicant_employer ?? '',
          applicant_employment_status:
            loanData?.employee_information?.applicant_employment_status ?? '',
          applicant_job_title: loanData?.employee_information?.applicant_job_title ?? '',
          applicant_employment_start_date:
            loanData?.employee_information?.applicant_employment_start_date,
          applicant_monthly_income: Number(
            loanData?.employee_information?.applicant_monthly_income
          ),
          applicant_other_income_source:
            loanData?.employee_information?.applicant_other_income_source ?? '',
          permenant_employee_number: Number(
            loanData?.employee_information?.permenant_employee_number
          ),
          casual_employee_number: Number(loanData?.employee_information?.casual_employee_number),
          previous_year_turnover: Number(loanData?.employee_information?.previous_year_turnover),
          farm_other_income: Number(loanData?.employee_information?.farm_other_income)
        },
        particulars_of_next_of_kin: {
          kin_first_name: loanData?.particulars_of_next_of_kin?.kin_first_name ?? '',
          kin_mid_name: loanData?.particulars_of_next_of_kin?.kin_mid_name ?? '',
          kin_last_name: loanData?.particulars_of_next_of_kin?.kin_last_name ?? '',
          kin_physical_address: loanData?.particulars_of_next_of_kin?.kin_physical_address ?? '',
          kin_postal_address: loanData?.particulars_of_next_of_kin?.kin_postal_address ?? '',
          kin_email: loanData?.particulars_of_next_of_kin?.kin_email ?? '',
          kin_phone: loanData?.particulars_of_next_of_kin?.kin_phone ?? '',
          last_update_by: Number(farmer?.user_id)
        },
        commercial_bank_details: {
          bank_id: Number(loanData?.commercial_bank_details?.bank_id),
          bank_branch_id: Number(loanData?.commercial_bank_details?.bank_branch_id),
          bank_account_number: Number(loanData?.commercial_bank_details?.bank_account_number),
          last_update_by: Number(farmer?.user_id)
        },
        loans_with_other_institutions_ndb:
          (loanData?.loans_with_other_institutions_ndb as OtherLoansDetails[]) ?? [],
        history_of_ndb_loans: {
          loan_exists: loanData?.history_of_ndb_loans?.loan_exists ?? 'no',
          last_update_by: Number(farmer?.user_id)
        },

        loan_spouse: {
          spouse_id: Number(loanData?.loan_spouse?.spouse_id),
          spouse_first_name: loanData?.loan_spouse.spouse_first_name ?? '',
          spouse_mid_name: loanData?.loan_spouse?.spouse_mid_name ?? '',
          spouse_last_name: loanData?.loan_spouse?.spouse_last_name ?? '',
          spouse_physical_address: loanData?.loan_spouse?.spouse_physical_address ?? '',
          spouse_employment_status: loanData?.loan_spouse?.spouse_employment_status ?? '',
          spouse_shareholding_value: loanData?.loan_spouse?.spouse_shareholding_value ?? '',
          spouse_income: Number((loanData?.loan_spouse?.spouse_income as unknown as number) ?? 0),
          spouse_email: loanData?.loan_spouse?.spouse_email ?? '',
          spouse_phone: loanData?.loan_spouse?.spouse_phone ?? '',
          marital_status: loanData?.loan_spouse?.marital_status ?? '',
          spouse_job_title: loanData?.loan_spouse?.spouse_job_title ?? '',
          spouse_employer: loanData?.loan_spouse?.spouse_employer ?? '',
          spouse_employment_start_date:
            loanData?.loan_spouse?.spouse_employment_start_date ?? new Date(Date.now())
        },
        financial_particulars: {
          fp_checklist1: loanData?.financial_particulars?.fp_checklist1 ?? '',
          fp_checklist2: loanData?.financial_particulars?.fp_checklist2 ?? '',
          fp_checklist3: loanData?.financial_particulars?.fp_checklist3 ?? '',
          fp_checklist4: Number(loanData?.financial_particulars?.fp_checklist4),
          fp_checklist5: loanData?.financial_particulars?.fp_checklist5 ?? '',
          last_update_by: Number(farmer?.user_id)
        },
        ...(loanData?.loan_security && {
          loan_security: {
            immovable_property: loanData?.loan_security
              ?.immovable_property as ImmovablePropertySecurity[],
            loan_machinery: {
              age: Number(loanData?.loan_security?.loan_machinery?.age),
              original_cost: Number(loanData?.loan_security?.loan_machinery?.original_cost),
              market_value: Number(loanData?.loan_security?.loan_machinery?.market_value),
              estimated_forces_sale_value: Number(
                loanData?.loan_security?.loan_machinery?.estimated_forces_sale_value
              ),
              encumbrances_amount: Number(
                loanData?.loan_security?.loan_machinery?.encumbrances_amount
              ),
              machinery_id: Number(loanData?.loan_security?.loan_machinery?.machinery_id)
            },
            loan_motor_vehicle: {
              age: Number(loanData?.loan_security?.loan_motor_vehicle?.age),
              original_cost: Number(loanData?.loan_security?.loan_motor_vehicle?.original_cost),
              market_value: Number(loanData?.loan_security?.loan_motor_vehicle?.market_value),
              estimated_forces_sale_value: Number(
                loanData?.loan_security?.loan_motor_vehicle?.estimated_forces_sale_value
              ),
              encumbrances_amount: Number(
                loanData?.loan_security?.loan_motor_vehicle?.encumbrances_amount
              ),
              vehicle_id: Number(loanData?.loan_security?.loan_motor_vehicle?.vehicle_id)
            },
            loan_bank_guarantee: {
              guarantee_value: Number(loanData?.loan_security?.loan_bank_guarantee?.guarantee_value)
            },
            loan_other_guarantee: {
              guarantee_value: Number(
                loanData?.loan_security?.loan_other_guarantee?.guarantee_value
              )
            },
            loan_insurance_policy: {
              sum_assured: Number(loanData?.loan_security?.loan_insurance_policy?.sum_assured)
            },
            loan_other_security: {
              fixed_deposit_balance: Number(
                loanData?.loan_security?.loan_other_security?.fixed_deposit_balance
              )
            },
            basis_of_estimation: {
              basis_of_estimation:
                loanData?.loan_security?.basis_of_estimation?.basis_of_estimation ?? ''
            }
          }
        }),
        production_history: loanData?.production_history as ProductionDetails[],
        summarized_business_information: {
          farmer_id: Number(farmer?.farmer_id),
          background_info: loanData?.summarized_business_information?.background_info ?? '',
          process_info: loanData?.summarized_business_information?.process_info ?? '',
          key_assum: loanData?.summarized_business_information?.key_assum ?? '',
          market_info: loanData?.summarized_business_information?.market_info ?? '',
          competitors: loanData?.summarized_business_information?.competitors ?? '',
          distribution: loanData?.summarized_business_information?.distribution ?? '',
          last_update_by: Number(farmer?.user_id)
        },
        balance_sheets: {
          general_information: {
            marital_regime: loanData?.balance_sheets?.general_information?.marital_regime ?? '',
            balance_sheet_date: loanData?.balance_sheets?.general_information?.balance_sheet_date
          },
          immovable_assets: loanData?.balance_sheets?.immovable_assets ?? [],
          ...(loanData?.balance_sheets?.movable_assets && {
            movable_assets: {
              movable_property_id: Number(
                loanData?.balance_sheets?.movable_assets?.movable_property_id
              ),
              company_name: loanData?.balance_sheets?.movable_assets?.company_name?.toString(),
              number_of_shares: Number(loanData?.balance_sheets?.movable_assets?.number_of_shares),
              estimated_share_value: Number(
                loanData?.balance_sheets?.movable_assets?.estimated_share_value
              ),
              total_number_of_shares: Number(
                loanData?.balance_sheets?.movable_assets?.total_number_of_shares
              ),
              type_of_assets: loanData?.balance_sheets?.movable_assets?.type_of_assets?.toString(),
              year_of_acquisition: loanData?.balance_sheets?.movable_assets?.year_of_acquisition,
              net_book_value: Number(loanData?.balance_sheets?.movable_assets?.net_book_value),
              estimated: Number(loanData?.balance_sheets?.movable_assets?.estimated_share_value),
              livestock_number: Number(loanData?.balance_sheets?.movable_assets?.livestock_number),
              ...(loanData.balance_sheets?.movable_assets?.livestock && {
                livestock: loanData?.balance_sheets?.movable_assets?.livestock
              }),
              recievables: Number(loanData?.balance_sheets?.movable_assets?.recievables),
              total_cash: Number(loanData?.balance_sheets?.movable_assets?.total_cash),
              fixed_deposits: Number(loanData?.balance_sheets?.movable_assets?.fixed_deposits),
              other_assets: Number(loanData?.balance_sheets?.movable_assets?.other_assets)
            }
          }),
          ...(loanData?.balance_sheets?.liablities && {
            liablities: {
              mortgage_loans: Number(loanData?.balance_sheets?.liablities?.mortgage_loans),
              hire_purchase_loans: Number(
                loanData?.balance_sheets?.liablities?.hire_purchase_loans
              ),
              tax_liabilities: Number(loanData?.balance_sheets?.liablities?.tax_liabilities),
              personal_loans: Number(loanData?.balance_sheets?.liablities?.personal_loans),
              creditors: Number(loanData?.balance_sheets?.liablities?.creditors),
              other_liabilities: Number(loanData?.balance_sheets?.liablities?.other_liabilities)
            }
          }),
          declaration: {
            plot_number: '',
            location: '',
            type_of_land: '',
            size: 0,
            remaining_lease_period: 0,
            estimated_value: 0
          }
        },

        last_update_by: Number(farmer?.user_id)
      },
      {
        onSuccess: (result) => {
          if (result.status === 201 && result.success) {
            setApplicationHead((prev) => ({
              ...prev,
              loan_application_id: Number(result?.data?.loan_application_id)
            }))

            toast({
              variant: 'success',
              title: 'Your loan is submitted to the CMS Database!!'
            })
            next()
            if (onComplete != null) {
              onComplete(true)
            }
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  return {
    sendToCMS,
    isLoading: mutation.isLoading
  }
}
