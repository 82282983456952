import type { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import dayjs from 'dayjs'
import type { FarmerVoucherTransaction } from 'types'

export const historyColumns: Array<ColumnDef<FarmerVoucherTransaction>> = [
  {
    id: 'transaction id',
    accessorFn: (row) => row.transaction_id,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('transaction id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Transaction ID' />
  },
  {
    id: 'date',
    accessorFn: (row) => row.date,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => (
      <div className='uppercase'>{dayjs(row.getValue('date')).format('DD-MM-YYYY')}</div>
    ),
    header: ({ column }) => <DataTableColumnHeader column={column} title='Date' />
  },
  {
    id: 'merchant',
    accessorFn: (row) => row.merchant_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('merchant')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Merchant' />
  },
  {
    id: 'type',
    accessorFn: (row) => {
      let transactionType = 'Unknown'

      const seedCount = row.seed_list?.length ?? 0
      const serviceCount = row.service_list?.length ?? 0
      const pesticideCount = row.pesticide_list?.length ?? 0
      const herbicideCount = row.herbicide_list?.length ?? 0
      const adjuvantCount = row.adjuvant_list?.length ?? 0
      const fungicideCount = row.fungicide_list?.length ?? 0

      if (seedCount > 0) {
        transactionType = 'Seeds'
      } else if (serviceCount > 0) {
        transactionType = 'Services'
      } else if (
        pesticideCount > 0 ||
        herbicideCount > 0 ||
        adjuvantCount > 0 ||
        fungicideCount > 0
      ) {
        transactionType = 'Chemicals'
      }

      return transactionType
    },
    filterFn: 'includesString',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Transaction Type' />,
    cell: ({ row }) => {
      const transactionType = String(row.getValue('type'))
      return <div className='uppercase'>{transactionType}</div>
    }
  },
  {
    id: 'amount',
    accessorFn: (row) => row.amount,
    filterFn: 'includesString',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Amount Used' />,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('amount'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]
