import type React from 'react'
import { useEffect, useState } from 'react'
import type z from 'zod'
import { coerce, object, string } from 'zod'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  useStepper
} from '../../../../../components/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { uniqBy } from 'lodash'
import { Box } from '../../../../../components/App/Layout'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'
import { Info } from 'lucide-react'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'

const schema = object({
  bankId: coerce.number().min(1, 'Field is required'),
  accountNumber: string().min(1, 'Field is required'),
  branchId: coerce.number().min(1, 'Field is required')
})

export const CommercialBank: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const { farmer } = useFarmerStore()
  const { nextStep } = useStepper()
  const [bankId, setBankId] = useState<string | number>()
  const { t } = useTranslation('loanManagement')
  const { banksInformation } = useStaticDataStore()
  useEffect(() => {
    if (loanData?.commercial_bank_details?.bank_id != null) {
      setBankId(loanData?.commercial_bank_details?.bank_id)
    }
  }, [loanData])

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      bankId: loanData?.commercial_bank_details?.bank_id,
      accountNumber: loanData?.commercial_bank_details?.bank_account_number.toString(),
      branchId: loanData?.commercial_bank_details?.bank_branch_id
    }
  })

  const bankNames = uniqBy(banksInformation, (item) => item.bank_id).sort((a, b) =>
    a.bank_name.localeCompare(b.bank_name)
  )

  const bankBranchNames = banksInformation
    ?.filter((sub) => sub.bank_id.toString() === bankId?.toString())
    .sort((a, b) => a.bank_branch_name.localeCompare(b.bank_branch_name))

  const onSubmit = (data: z.infer<typeof schema>): void => {
    setLoanData((prev) => ({
      ...prev!,
      commercial_bank_details: {
        bank_id: data.bankId,
        bank_branch_id: data.branchId,
        bank_account_number: Number(data.accountNumber),
        last_update_by: Number(farmer?.user_id)
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className='flex flex-col w-full' onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <FormLabel className={'font-redHat'}>
              <FormLabel className={'font-redHat'}>
                <div className='flex flex-col gap-3'>
                  <Text size='medium' className=''>
                    {t('bank.bank')}
                  </Text>
                  <div className='flex items-center'>
                    <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                    <Text variant='secondary' size='small' className=''>
                      Provide information about the bank account you would like to use for the loan.
                    </Text>
                  </div>
                </div>
              </FormLabel>
            </FormLabel>

            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mb-3'>
              <FormField
                control={form.control}
                name='bankId'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('bank.name')}
                      </Text>
                    </FormLabel>
                    <Select
                      onValueChange={(val) => {
                        field.onChange(val)
                        setBankId(val)
                      }}
                      value={field.value?.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('bank.name')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {bankNames.map((item) => (
                          <SelectItem key={item.bank_id} value={item.bank_id.toString()}>
                            <Text size='body' className=''>
                              {item.bank_name.toUpperCase()}
                            </Text>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='branchId'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('bank.branch')}
                      </Text>
                    </FormLabel>
                    <Select
                      onValueChange={(val) => {
                        field.onChange(val)
                      }}
                      value={field.value?.toString()}
                      disabled={!bankId}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('bank.branch')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {bankBranchNames?.map((item) => (
                          <SelectItem
                            key={item.bank_branch_id}
                            value={item.bank_branch_id.toString()}
                          >
                            <Text size='body' className=''>
                              {item.bank_branch_name.toUpperCase()}
                            </Text>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='accountNumber'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('bank.acc')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel='bg-background-dark sm:bg-card'
                        placeholder={`${t('bank.acc')}`}
                        {...field}
                        type='number'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <LoanStepperFooter />
          </div>
        </form>
      </Form>
    </Box>
  )
}
