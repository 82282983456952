import type React from 'react'
import { useEffect, useState } from 'react'
import { Box, TabView } from '../../../../components/App/Layout'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../../components/ui'
import { coerce, object, string, z } from 'zod'
import { useFarmerStore } from 'stores/useFarmerStore'
import { useForm } from 'react-hook-form'
import { YearPicker } from '../../../../components/Form/YearPicker'
import { useNavigate } from 'react-router-dom'
import { type GetInventoryRes, type Implement } from '../types'
import { zodResolver } from '@hookform/resolvers/zod'
import { uniqBy } from 'lodash'
import dayjs from 'dayjs'
import { isLease } from '../../../officer/farmers/utils/funcitons'
import { type VerifiedFarm } from '../util'
import { useInventoryItem } from '../../../merchant/service-provider/inventory/hooks/useInventoryItem'

import { useOfficerStore } from '../../../../stores/useOfficerStore'
import { type Farm, type Lease } from '../../../../types'
import { useTranslation } from 'react-i18next'
import { useAuthenticationStore } from '../../../../stores/useAuthenticationStore'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'

export const NonPoweredImpSchema = object({
  yom: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  reg_number: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  model: z.coerce.number().min(1, 'Field is required'),
  make: z.coerce.number().min(1, 'Field is required'),
  class: string().optional().or(z.literal('')).default(''),
  type: string().optional().or(z.literal('')).default(''),
  subType: string().optional().or(z.literal('')).default(''),
  bottoms: string().optional().or(z.literal('')).default(''),
  workingWidth: string().optional().or(z.literal('')).default(''),
  draftPower: string().min(1, 'Field is required'),
  system: string().optional().or(z.literal('')).default(''),
  attachment: string().optional().or(z.literal('')).default(''),
  farm: coerce.number().min(1, 'Field is required')
})

export type InventoryInput = z.infer<typeof NonPoweredImpSchema>

export interface RegisterInventoryProps {
  inventoryData?: GetInventoryRes
  inventoryItem?: Implement
  isView?: boolean
  isMerchant?: boolean
  farmsList: VerifiedFarm[]
}

export const RegisterNonPoweredImplements: React.FC<RegisterInventoryProps> = ({
  inventoryData,
  inventoryItem,
  isView,
  isMerchant,
  farmsList
}) => {
  const { officer } = useOfficerStore()
  const { role } = useAuthenticationStore()
  const [selectedMake, setSelectedMake] = useState<string | number>(
    inventoryItem?.make_id.toString() ?? ''
  )
  const [selectedModel, setSelectedModel] = useState<string | number>(
    inventoryItem?.model_id.toString() ?? ''
  )
  const [selectedFarm, setSelectedFarm] = useState<string | number>(
    inventoryData?.farm_id ?? inventoryData?.lease_id ?? ''
  )
  const { t } = useTranslation('inventory')

  const { farmer } = useFarmerStore()
  const { nonPoweredImplements } = useStaticDataStore()

  const { createItem, updateItem, isLoading } = useInventoryItem()
  const [draftPower, setDraftPower] = useState<string>(inventoryItem?.draft_power ?? '')
  const [selectedItem, setSelectedItem] = useState<Implement | undefined>()
  const [currentSelectedFarm, setCurrentSelectedFarm] = useState<Farm | Lease | undefined>()
  const form = useForm<InventoryInput>({
    resolver: zodResolver(NonPoweredImpSchema),
    defaultValues: {
      model: Number(selectedModel) ?? Number(inventoryItem?.model_id) ?? '',
      make: Number(selectedMake) ?? Number(inventoryItem?.make_id) ?? '',
      class: inventoryItem?.implement_class ?? '',
      workingWidth: inventoryItem?.working_width ?? '',
      subType: inventoryItem?.implement_sub_type ?? '',
      type: inventoryItem?.implement_type ?? '',
      reg_number: inventoryData?.chassis_number?.toString(),
      draftPower: draftPower ?? inventoryItem?.draft_power ?? '',
      system: inventoryItem?.implement_system ?? '',
      attachment: inventoryItem?.attachment ?? '',
      bottoms:
        inventoryItem?.implement_class === 'SEEDER'
          ? inventoryItem?.no_of_rows?.toString()
          : inventoryItem?.no_of_bottoms?.toString(),
      farm:
        Number(selectedFarm) ??
        Number(inventoryData?.farm_id) ??
        Number(inventoryData?.lease_id) ??
        ''
    }
  })

  const findSelectedItem = (): Implement | undefined => {
    return nonPoweredImplements?.find(
      (item) =>
        item.make_id.toString() === selectedMake &&
        item.model_id.toString() === selectedModel &&
        item.draft_power === draftPower
    )
  }

  const findSelectedFarm = (): Farm | Lease | undefined =>
    farmsList?.find(
      (item) =>
        item.farm_id?.toString() === selectedFarm?.toString() ||
        (item as Lease).lease_id?.toString() === selectedFarm?.toString()
    )

  const [isFormChanged, setIsFormChanged] = useState(false)
  useEffect(() => {
    if (!isFormChanged) {
      setSelectedMake(inventoryItem?.make_id.toString() ?? '')
      setSelectedModel(inventoryItem?.model_id.toString() ?? '')
      setSelectedFarm(inventoryData?.farm_id ?? inventoryData?.lease_id ?? '')
    }

    form.setValue('make', Number(selectedMake))
    form.setValue('model', Number(selectedModel))
    form.setValue('farm', Number(selectedFarm))
    setSelectedItem(findSelectedItem())
    setCurrentSelectedFarm(findSelectedFarm())
  }, [inventoryData, inventoryItem, selectedMake, selectedModel, selectedFarm, form, isFormChanged])
  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      setIsFormChanged(true)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [form])

  const navigate = useNavigate()

  const draftPowers = uniqBy(nonPoweredImplements, (item) => {
    return item.draft_power ?? ''
  }).sort((a, b) => (a?.draft_power ?? '').localeCompare(b?.draft_power ?? ''))

  const makes = uniqBy(nonPoweredImplements, (item) => {
    return item.make_id
  })
    .filter((make) => make.draft_power === draftPower)
    .sort((a, b) => a.make.localeCompare(b.make))

  const models = uniqBy(nonPoweredImplements, (item) => {
    return item.model_id
  })
    .filter((sub) => sub.make_id.toString() === selectedMake?.toString())
    .sort((a, b) => a.model.localeCompare(b.model))

  const onSubmit = (data: InventoryInput): void => {
    const farmOrLeaseId = isLease(currentSelectedFarm)
      ? { lease_id: currentSelectedFarm?.lease_id }
      : { farm_id: currentSelectedFarm?.farm_id }

    const payload = {
      farmer_id: farmer?.farmer_id,
      yom: dayjs(String(data.yom)).format('YYYY'),
      chassis_number: data.reg_number,
      resource_item: Number(selectedItem?.model_id),
      inventory_item_verification_status_id: 'PENDING_VERIFICATION',
      last_updated_by: Number(farmer?.user_id),
      inventory_type_id: 1002,
      ...farmOrLeaseId
    }

    if (inventoryData != null) {
      updateItem({ ...payload, inventory_id: inventoryData.inventory_id })
    } else {
      createItem(payload)
    }
  }

  return (
    <Box>
      <TabView className={'max-w-[900px]'}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col gap-8'}>
            <div className='grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] items-start gap-4 '>
              <FormField
                control={form.control}
                name={`reg_number`}
                defaultValue={inventoryData?.reg_number}
                disabled={isView === true || isMerchant}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        placeholder={`${t('nonPwrd.serial')}`}
                        classLabel={'bg-card'}
                        label={`${t('nonPwrd.serial')}`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className={'w-full mt-2 h-full'}>
                <YearPicker
                  error={!(form.formState.errors.yom == null)}
                  helperText={form.formState.errors?.yom?.message ?? ''}
                  label={`${t('nonPwrd.year')}`}
                  name={'yom'}
                  className={'w-full'}
                  control={form.control}
                  defaultValue={inventoryData !== null ? dayjs(inventoryData?.yom) : undefined}
                  disabled={isView === true || isMerchant}
                />
              </div>
              <FormField
                control={form.control}
                name='draftPower'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setDraftPower(val)
                        setSelectedMake('')
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      value={draftPower?.toString()}
                      disabled={isView === true || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('nonPwrd.draft')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {draftPowers?.map((disc) => {
                          if (disc?.draft_power == null || disc?.draft_power === '') {
                            return null
                          }

                          return (
                            <SelectItem key={disc.model_id} value={disc?.draft_power ?? ''}>
                              {disc.draft_power}
                            </SelectItem>
                          )
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='make'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      disabled={draftPower === '' || isView === true || isMerchant}
                      onValueChange={(val) => {
                        setSelectedMake(val)
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      value={selectedMake?.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('nonPwrd.make')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {makes?.map((disc) => (
                          <SelectItem key={disc.make_id} value={disc.make_id.toString()}>
                            {disc.make}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='model'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      disabled={selectedMake === '' || isView === true || isMerchant}
                      onValueChange={(val) => {
                        setSelectedModel(val)
                        field.onChange(val)
                      }}
                      value={selectedModel?.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('nonPwrd.model')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {models?.map((disc) => (
                          <SelectItem key={disc.model_id} value={disc.model_id.toString()}>
                            {disc.model}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`class`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        label={`${t('nonPwrd.class')}`}
                        placeholder={`${t('nonPwrd.class')}`}
                        {...field}
                        value={
                          selectedItem?.implement_class != null
                            ? selectedItem?.implement_class?.toString()
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`type`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem?.implement_type != null
                            ? selectedItem?.implement_type.toString()
                            : 'N/A'
                        }
                        label={`${t('nonPwrd.type')}`}
                        placeholder={`${t('nonPwrd.type')}`}
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`subType`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem?.implement_sub_type != null
                            ? selectedItem?.implement_sub_type.toString()
                            : 'N/A'
                        }
                        label={`${t('nonPwrd.sub')}`}
                        placeholder={`${t('nonPwrd.sub')}`}
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`bottoms`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem != null && selectedItem?.implement_class === 'SEEDER'
                            ? selectedItem?.no_of_rows?.toString()
                            : selectedItem?.no_of_bottoms?.toString() ?? 'N/A'
                        }
                        label={
                          draftPower === ''
                            ? `${t('nonPwrd.rowsBottoms')}`
                            : inventoryItem?.implement_class === 'SEEDER'
                            ? `${t('nonPwrd.rows')}`
                            : `${t('nonPwrd.bottoms')}`
                        }
                        placeholder={
                          draftPower === ''
                            ? `${t('nonPwrd.rowsBottoms')}`
                            : inventoryItem?.implement_class === 'SEEDER'
                            ? `${t('nonPwrd.rows')}`
                            : `${t('nonPwrd.bottoms')}`
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`workingWidth`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem?.working_width != null
                            ? selectedItem?.working_width?.toString()
                            : 'N/A'
                        }
                        label={`${t('nonPwrd.width')}`}
                        placeholder={`${t('nonPwrd.width')}`}
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`system`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem?.implement_system != null
                            ? selectedItem?.implement_system?.toString()
                            : 'N/A'
                        }
                        label={`${t('nonPwrd.system')}`}
                        placeholder={`${t('nonPwrd.system')}`}
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`attachment`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem?.attachment != null
                            ? selectedItem?.attachment?.toString()
                            : 'N/A'
                        }
                        label={`${t('nonPwrd.att')}`}
                        placeholder={`${t('nonPwrd.att')}`}
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='farm'
                render={({ field }) => (
                  <FormItem>
                    {officer != null ? (
                      <Input
                        type='text'
                        label={`${t('nonPwrd.farm')}`}
                        classLabel={'bg-card'}
                        placeholder={`${t('nonPwrd.farm')}`}
                        value={inventoryData?.farm_name?.toString() ?? ''}
                        disabled
                      />
                    ) : (
                      <Select
                        onValueChange={(val) => {
                          field.onChange(val)
                          setSelectedFarm(val)
                        }}
                        value={selectedFarm?.toString()}
                        disabled={isView === true || isMerchant}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={`${t('nonPwrd.selectFarm')}`} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {farmsList?.map((disc) => (
                            <SelectItem key={disc.farm_id} value={disc.farm_id?.toString()}>
                              {disc.farm_name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className='flex w-full justify-between space-x-4'>
              <Button
                className='w-full sm:w-[18rem]'
                variant='secondary'
                onClick={() => {
                  navigate(-1)
                }}
              >
                {t('nonPwrd.cancel')}
              </Button>
              {isView === true &&
                isMerchant === false &&
                inventoryData?.item_verification_status !== 'PENDING_VERIFICATION' && (
                  <Button
                    className='w-full sm:w-[18rem]'
                    onClick={() => {
                      navigate(
                        `${role === 1001 ? '/officer' : '/farmer'}/inventory/assessment/${Number(
                          inventoryData?.inventory_id
                        )}`
                      )
                    }}
                  >
                    {t('nonPwrd.view')}
                  </Button>
                )}
              {isView === false && isMerchant === false && (
                <Button
                  className='w-full sm:w-[18rem]'
                  type='submit'
                  disabled={isLoading || (inventoryData != null && !isFormChanged)}
                  loading={isLoading}
                >
                  {inventoryData != null ? `${t('nonPwrd.update')}` : `${t('nonPwrd.submit')}`}
                </Button>
              )}
              {isMerchant === true && (
                <Button
                  className='w-full sm:w-[18rem]'
                  onClick={() => {
                    navigate(`/inventory/assessment/${Number(inventoryData?.inventory_id)}`)
                  }}
                >
                  {inventoryData?.item_verification_status === 'PENDING_VERIFICATION'
                    ? `${t('nonPwrd.new')}`
                    : `${t('nonPwrd.view')}`}
                </Button>
              )}
            </div>
          </form>
        </Form>
      </TabView>
    </Box>
  )
}
