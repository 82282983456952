import {
  CategoryLabel,
  FieldValue,
  RowContainer
} from 'features/loan-management/components/stepper'
import { useAtom } from 'jotai/index'
import { applicationHeader } from '../stores/applicationHeader'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useGetCompanyLoan } from '../../../api/getCompanyLoan'
import type React from 'react'
import { useState } from 'react'
import type { LoanStepperProps } from '../../../types'
import moment from 'moment/moment'
import { Box } from '../../../../../components/App/Layout'
import { Text, toast, useStepper } from '../../../../../components/ui'
import { Info } from 'lucide-react'
import { Spinner } from '../../../../../components/Elements/Spinner'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'
import { usePDF } from 'react-to-pdf'

export const ConfirmCompanyDetails: React.FC<LoanStepperProps> = ({ onComplete, cancel }) => {
  const [loanId] = useAtom(applicationHeader)
  const { farmer } = useFarmerStore()
  const { nextStep } = useStepper()
  const { data: loanData, isLoading } = useGetCompanyLoan(
    Number(farmer?.farmer_id),
    loanId?.loan_application_id as unknown as number
  )
  const { toPDF, targetRef } = usePDF({ filename: 'company_loan_details.pdf' })
  const [pdfDownloaded, setPdfDownloaded] = useState(false)
  const { t } = useTranslation('loanManagement')
  const handleDownloadPdf = async (): Promise<void> => {
    await new Promise<void>((resolve) => {
      toPDF()
      resolve()
    })
    setPdfDownloaded(true)
    toast({
      variant: 'destructive',
      title: 'Pdf is printing!! Upload it on the next page'
    })
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  // Other Loans Data
  const loans = loanData?.data?.loans_with_other_institutions_ndb
    ?.map((loan, index) => [
      {
        label: `${t('loan.loan')} ${index + 1}`,
        value: '',
        isCurrency: false
      },
      {
        label: t('loan.reff'),
        value: loan?.ref_no,
        isCurrency: false
      },
      {
        label: t('loan.duration'),
        value: loan?.loan_duration,
        isCurrency: false
      },
      {
        label: t('loan.rate'),
        value: loan?.int_rate,
        isCurrency: false
      },
      {
        label: t('loan.app'),
        value: loan?.amount_approved,
        isCurrency: true
      },
      {
        label: t('loan.bal'),
        value: loan?.outstanding_balance,
        isCurrency: true
      },
      {
        label: t('loan.inst'),
        value: loan?.installment,
        isCurrency: true
      },
      {
        label: t('loan.arr'),
        value: loan?.arrears,
        isCurrency: true
      },
      {
        label: t('loan.date'),
        value: moment(loan?.date_aqcuired).format('DD/MM/YYYY'),
        isCurrency: false
      }
    ])
    .flat()

  // Immovable Properties Data
  const properties = loanData?.data?.security_immovable_property
    ?.map((property, index) => [
      {
        label: `${t('sec.pr')} ${index + 1}`,
        value: '',
        isCurrency: false
      },
      {
        label: t('sec.age'),
        value: property?.age,
        isCurrency: false
      },
      {
        label: t('sec.oc'),
        value: property?.original_cost,
        isCurrency: true
      },
      {
        label: t('sec.mv'),
        value: property?.market_value,
        isCurrency: true
      },
      {
        label: t('sec.efs'),
        value: property?.estimated_forces_sale_value,
        isCurrency: true
      },
      {
        label: t('sec.ea'),
        value: property?.encumbrances_amount,
        isCurrency: true
      }
    ])
    .flat()

  // Production Details Data
  const productionDetails = loanData?.data?.production_history
    ?.map((loan, index) => [
      {
        label: `${t('prod.prodd')} ${index + 1}`,
        value: '',
        isCurrency: false
      },
      {
        label: t('prod.cn'),
        value: loan.crop_name,
        isCurrency: false
      },
      {
        label: t('prod.ton'),
        value: loan.tonnage,
        isCurrency: false
      },
      {
        label: t('prod.yr'),
        value: moment(loan.production_year).format('YYYY'),
        isCurrency: false
      }
    ])
    .flat()

  // Immovable Assets Data
  const immovableAssets = loanData?.data?.balance_sheet_immovable_assets
    ?.map((asset, index) => [
      {
        label: `${t('bal.ass')} ${index + 1}`,
        value: '',
        isCurrency: false
      },
      {
        label: t('bal.plotNo'),
        value: asset?.plot_number,
        isCurrency: false
      },
      {
        label: t('bal.loc'),
        value: asset?.property_location,
        isCurrency: false
      },
      {
        label: t('bal.type'),
        value: asset?.type_of_land,
        isCurrency: false
      },
      {
        label: t('bal.sz'),
        value: asset?.property_size,
        isCurrency: false
      },
      {
        label: t('bal.rlp'),
        value: asset?.remaining_lease_period,
        isCurrency: false
      },
      {
        label: t('bal.ev'),
        value: asset?.estimated_value,
        isCurrency: true
      }
    ])
    .flat()

  // Livestock Data
  const livestockInformation = loanData?.data?.livestock_information
    ?.map((livestock, index) => [
      {
        label: `${t('bal.lv')} ${index + 1}`,
        value: '',
        isCurrency: false
      },
      {
        label: t('bal.lvn'),
        value: livestock.livestock_id,
        isCurrency: false
      },
      {
        label: t('bal.lvno'),
        value: livestock.livestock_number,
        isCurrency: false
      }
    ])
    .flat()

  const croppingData = loanData?.data?.cropping_plan
    ?.map((plan, index) => [
      {
        label: `${t('confirm.cp')} ${index + 1}`,
        value: '',
        isCurrency: false
      },
      {
        label: 'Name',
        value: plan.crop_name,
        isCurrency: false
      },
      {
        label: 'Hectrage',
        value: `${plan.hectarage}ha`,
        isCurrency: false
      }
    ])
    .flat()

  return (
    <Box>
      <div className='flex flex-col space-y-8 my-6'>
        <div className='flex flex-col gap-3'>
          <Text size='medium' className=''>
            {t('confirm.please')}:
          </Text>
          <div className='flex items-center'>
            <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
            <Text variant='secondary' size='small' className=''>
              Check the details of the loan application before proceeding to the next step. A pdf
              displaying this entire information will be downloaded as you proceed to the next step.
            </Text>
          </div>
        </div>
        {isLoading ? (
          <div className={'w-full grid place-items-center h-full flex-1'}>
            <Spinner size={'sm'} />
          </div>
        ) : (
          <div ref={targetRef} className='flex flex-col space-y-8 p-6'>
            <CategoryLabel label={`${t('confirm.details')}`} variant='secondary' />
            <RowContainer>
              {[
                {
                  label: t('confirm.loan'),
                  value: String(loanData?.data?.application_header?.loan_application_id)
                },
                {
                  label: t('confirm.name'),
                  value: `${loanData?.data?.application_header?.title as unknown as string} ${
                    loanData?.data?.application_header?.firstname as unknown as string
                  } ${loanData?.data?.application_header?.surname as unknown as string}`
                },
                {
                  label: t('confirm.cms'),
                  value: loanData?.data?.application_header?.farmer_id
                }
              ].map((item, index) => (
                <FieldValue
                  key={index}
                  label={item.label}
                  value={item?.value as unknown as string}
                />
              ))}
            </RowContainer>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Loan Details */}

            <CategoryLabel label='loan details' variant='secondary' />
            <RowContainer>
              <div className='flex gap-2 flex-[1_0_200px]'>
                {croppingData?.map((info, index) => (
                  <FieldValue
                    key={index}
                    label={info.label}
                    value={info.value.toString()}
                    isCurrency={false}
                  />
                ))}
              </div>

              <FieldValue
                label={`${t('confirm.amount')} (BWP)`}
                value={loanData?.data?.application_header.loan_requirement1 as unknown as string}
                isCurrency={true}
              />
            </RowContainer>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            <div className={'w-full '}>
              <CategoryLabel label={`${t('confirm.lInfo')}`} variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: t('pInfo.pay'),
                    value: loanData?.data?.application_header?.loan_requirement2
                  },
                  {
                    label: t('pInfo.purpose'),
                    value: loanData?.data?.application_header?.loan_requirement3
                  },
                  {
                    label: t('pInfo.project'),
                    value: loanData?.data?.application_header?.loan_requirement4
                  },
                  {
                    label: t('pInfo.previous'),
                    value: loanData?.data?.application_header?.period_at_physical_address
                  },
                  {
                    label: t('pInfo.physical'),
                    value: loanData?.data?.application_header
                      ?.period_at_prev_physical_address as number
                  },
                  {
                    label: t('confirm.source'),
                    value: loanData?.data?.application_header?.source_reference
                  },
                  {
                    label: t('pInfo.own'),
                    value:
                      loanData?.data?.application_header?.ownership_at_physical_address === '0'
                        ? 'Owning'
                        : 'Renting'
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                    isCurrency={false}
                  />
                ))}
              </RowContainer>
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            <div className={'w-full '}>
              <CategoryLabel label={`${t('confirm.eInfo')}`} variant='secondary' />
              <RowContainer>
                {[
                  // {
                  //   label: t('eInfo.employer'),
                  //   value: loanData?.data?.applicant_employment_status?.employer,
                  //   isCurrency: false
                  // },
                  // {
                  //   label: t('eInfo.eStatus'),
                  //   value: loanData?.data?.applicant_employment_status?.employement_status,
                  //   isCurrency: false
                  // },
                  // {
                  //   label: t('eInfo.title'),
                  //   value: loanData?.data?.applicant_employment_status?.job_title,
                  //   isCurrency: false
                  // },
                  // {
                  //   label: t('eInfo.start'),
                  //   value: moment(
                  //     loanData?.data?.applicant_employment_status?.employment_start_date
                  //   ).format('DD/MM/YYY'),
                  //   isCurrency: false
                  // },
                  // {
                  //   label: t('eInfo.monthly'),
                  //   value: loanData?.data?.applicant_employment_status?.monthly_income,
                  //   isCurrency: true
                  // },
                  // {
                  //   label: t('eInfo.other'),
                  //   value: loanData?.data?.applicant_employment_status?.other_income_source,
                  //   isCurrency: false
                  // },
                  // {
                  //   label: t('eInfo.otherIncome'),
                  //   value: loanData?.data?.applicant_employment_status?.other_income_amount,
                  //   isCurrency: true
                  // },
                  {
                    label: t('eInfo.noPerm'),
                    value: loanData?.data?.employee_information?.permenant_employee_number,
                    isCurrency: false
                  },
                  {
                    label: t('eInfo.noCas'),
                    value: loanData?.data?.employee_information?.casual_employee_number,
                    isCurrency: false
                  },
                  {
                    label: t('eInfo.prev'),
                    value: loanData?.data?.employee_information?.previous_year_turnover,
                    isCurrency: true
                  },
                  {
                    label: t('eInfo.farmIncome'),
                    value: loanData?.data?.employee_information?.other_income,
                    isCurrency: true
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                    isCurrency={item.isCurrency}
                  />
                ))}
              </RowContainer>
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Next of Kin */}

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Commercial Bank */}
            <div className={'w-full '}>
              <CategoryLabel label={`${t('bank.bank')}`} variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: t('bank.name'),
                    value: loanData?.data?.bank_details?.bank_name,
                    isCurrency: false
                  },
                  {
                    label: t('bank.branch'),
                    value: loanData?.data?.bank_details?.branch_name,
                    isCurrency: false
                  },
                  {
                    label: t('bank.location'),
                    value: loanData?.data?.bank_details?.branch_location,
                    isCurrency: false
                  },
                  {
                    label: t('bank.acc'),
                    value: loanData?.data?.bank_details?.bank_account_number,
                    isCurrency: false
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                    isCurrency={item.isCurrency}
                  />
                ))}
              </RowContainer>
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Current NDB Loans */}
            <div className={'w-full '}>
              <CategoryLabel label={`${t('loan.ndb')}`} variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: t('loan.ndbPrompt'),
                    value: loanData?.data?.history_of_ndb_loans?.loan_exists,
                    isCurrency: false
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                    isCurrency={item.isCurrency}
                  />
                ))}
              </RowContainer>
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Other Loans */}
            <div className={'w-full '}>
              <CategoryLabel label={`${t('loan.other')}`} variant='secondary' />
              <RowContainer>
                {loanData?.data?.loans_with_other_institutions_ndb?.length !== 0 ? (
                  <>
                    {' '}
                    {loans?.map((loan, index) => (
                      <FieldValue
                        key={index}
                        label={loan.label}
                        value={loan.value?.toString()}
                        isCurrency={loan.isCurrency}
                      />
                    ))}
                  </>
                ) : (
                  <Text size='body' className='ml-3'>
                    N/A
                  </Text>
                )}
              </RowContainer>
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Financial Particulars */}
            <div className={'w-full '}>
              <CategoryLabel label={`${t('part.fin')}`} variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: t('part.cr'),
                    value: loanData?.data?.financial_particulars.fp_checklist1,
                    isCurrency: false
                  },
                  {
                    label: t('part.cc'),
                    value: loanData?.data?.financial_particulars.fp_checklist2,
                    isCurrency: false
                  },
                  {
                    label: t('part.bl'),
                    value: loanData?.data?.financial_particulars.fp_checklist3,
                    isCurrency: false
                  },
                  {
                    label: t('part.bla'),
                    value: loanData?.data?.financial_particulars.fp_checklist4,
                    isCurrency: true
                  },
                  {
                    label: t('part.ag'),
                    value: loanData?.data?.financial_particulars.fp_checklist5,
                    isCurrency: false
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                    isCurrency={item.isCurrency}
                  />
                ))}
              </RowContainer>
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Loan Security */}
            {/* Immovable Property */}
            <div className={'w-full'}>
              <CategoryLabel label={t('sec.ls')} variant='secondary' />

              {loanData?.data?.security_life_insurance != null &&
              loanData?.data?.security_basis_of_estimation != null &&
              loanData?.data?.security_bank_guarantee != null &&
              loanData?.data?.security_immovable_property != null &&
              loanData?.data?.security_machinery != null &&
              loanData?.data?.loan_security_vehicle != null ? (
                <>
                  <div className='py-2.5'>
                    <Text size={'bodyBold'} className=''>
                      {t('sec.imm')}
                    </Text>
                    <RowContainer>
                      {properties?.map((property, index) => (
                        <FieldValue
                          key={index}
                          label={property.label}
                          value={property.value?.toString()}
                          isCurrency={property.isCurrency}
                        />
                      ))}
                    </RowContainer>
                  </div>

                  {/* Loan Machinery */}
                  <div className='py-2.5'>
                    <Text size={'bodyBold'} className=''>
                      {t('sec.lm')}
                    </Text>
                    <RowContainer>
                      {[
                        {
                          label: t('sec.age'),
                          value: loanData?.data?.security_machinery?.age,
                          isCurrency: false
                        },
                        {
                          label: t('sec.oc'),
                          value: loanData?.data?.security_machinery?.original_cost,
                          isCurrency: true
                        },
                        {
                          label: t('sec.mv'),
                          value: loanData?.data?.security_machinery?.market_value,
                          isCurrency: true
                        },
                        {
                          label: t('sec.efs'),
                          value: loanData?.data?.security_machinery?.estimated_forces_sale_value,
                          isCurrency: true
                        },
                        {
                          label: t('sec.ea'),
                          value: loanData?.data?.security_machinery?.encumbrances_amount,
                          isCurrency: true
                        }
                      ].map((item, index) => (
                        <FieldValue
                          key={index}
                          label={item.label}
                          value={item?.value as unknown as string}
                          isCurrency={item.isCurrency}
                        />
                      ))}
                    </RowContainer>
                  </div>

                  {/* Loan Motor Vehicle */}
                  <div className='py-2.5'>
                    <Text size={'bodyBold'} className=''>
                      {t('sec.lmv')}
                    </Text>

                    <RowContainer>
                      {[
                        {
                          label: t('sec.mvr'),
                          value: loanData?.data?.loan_security_vehicle?.vehicle_id,
                          isCurrency: false
                        },
                        {
                          label: t('sec.age'),
                          value: loanData?.data?.loan_security_vehicle?.age,
                          isCurrency: false
                        },
                        {
                          label: t('sec.oc'),
                          value: loanData?.data?.loan_security_vehicle?.original_cost,
                          isCurrency: true
                        },
                        {
                          label: t('sec.mv'),
                          value: loanData?.data?.loan_security_vehicle?.market_value,
                          isCurrency: true
                        },
                        {
                          label: t('sec.efs'),
                          value: loanData?.data?.loan_security_vehicle?.estimated_forces_sale_value,
                          isCurrency: true
                        },
                        {
                          label: t('sec.ea'),
                          value: loanData?.data?.loan_security_vehicle?.encumbrances_amount,
                          isCurrency: true
                        }
                      ].map((item, index) => (
                        <FieldValue
                          key={index}
                          label={item.label}
                          value={item?.value as unknown as string}
                          isCurrency={item.isCurrency}
                        />
                      ))}
                    </RowContainer>
                  </div>
                  <RowContainer>
                    {[
                      {
                        label: t('sec.lbgv'),
                        value: loanData?.data?.security_bank_guarantee.guarantee_value,
                        isCurrency: true
                      }
                      // ... add data items here ...
                    ].map((item, index) => (
                      <FieldValue
                        key={index}
                        label={item.label}
                        value={item?.value as unknown as string}
                        isCurrency={item.isCurrency}
                      />
                    ))}

                    {[
                      {
                        label: t('sec.logv'),
                        value: loanData?.data?.security_other_guarantee.fixed_deposit_balance,
                        isCurrency: true
                      }
                      // ... add data items here ...
                    ].map((item, index) => (
                      <FieldValue
                        key={index}
                        label={item.label}
                        value={item?.value as unknown as string}
                        isCurrency={item.isCurrency}
                      />
                    ))}
                    {[
                      {
                        label: t('sec.lsa'),
                        value: loanData?.data?.security_life_insurance.sum_assured,
                        isCurrency: true
                      }
                      // ... add data items here ...
                    ].map((item, index) => (
                      <FieldValue
                        key={index}
                        label={item.label}
                        value={item?.value as unknown as string}
                        isCurrency={item.isCurrency}
                      />
                    ))}
                    {[
                      {
                        label: t('sec.fd'),
                        value: loanData?.data?.security_other_guarantee.fixed_deposit_balance,
                        isCurrency: true
                      }
                      // ... add data items here ...
                    ].map((item, index) => (
                      <FieldValue
                        key={index}
                        label={item.label}
                        value={item?.value as unknown as string}
                        isCurrency={item.isCurrency}
                      />
                    ))}
                    {[
                      {
                        label: t('sec.bas'),
                        value: loanData?.data?.security_basis_of_estimation.basis_of_estimation,
                        isCurrency: false
                      }
                      // ... add data items here ...
                    ].map((item, index) => (
                      <FieldValue
                        key={index}
                        label={item.label}
                        value={item?.value as unknown as string}
                        isCurrency={item.isCurrency}
                      />
                    ))}
                  </RowContainer>
                </>
              ) : (
                <Text size='body' className=''>
                  N/A
                </Text>
              )}
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Production History */}
            <div className={'w-full '}>
              <CategoryLabel label={`${t('prod.prod')}`} variant='secondary' />
              <RowContainer>
                {productionDetails?.map((detail, index) => (
                  <FieldValue
                    key={index}
                    label={detail.label}
                    value={detail.value.toString()}
                    isCurrency={detail.isCurrency}
                  />
                ))}
              </RowContainer>
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Summarised Business Information */}
            <div className={'w-full '}>
              <CategoryLabel label={`${t('summ.summ')}`} variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: t('summ.bg'),
                    value: loanData?.data?.summarized_business_information?.background_information,
                    isCurrency: false
                  },
                  {
                    label: t('summ.pi'),
                    value: loanData?.data?.summarized_business_information?.process_information,
                    isCurrency: false
                  },
                  {
                    label: t('summ.ka'),
                    value: loanData?.data?.summarized_business_information?.key_assumption,
                    isCurrency: false
                  },
                  {
                    label: t('summ.mInfo'),
                    value: loanData?.data?.summarized_business_information?.market_information,
                    isCurrency: false
                  },
                  {
                    label: t('summ.competitors'),
                    value: loanData?.data?.summarized_business_information?.competitors,
                    isCurrency: false
                  },
                  {
                    label: t('summ.dist'),
                    value: loanData?.data?.summarized_business_information?.distribution,
                    isCurrency: false
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                    isCurrency={item.isCurrency}
                  />
                ))}
              </RowContainer>
            </div>

            {/* A line to separate the sections */}
            <hr className='my-4 border-gray-300' />

            {/* Personal Balance Sheet */}

            <div className={'w-full '}>
              <CategoryLabel label={t('bal.bal')} variant='secondary' />

              {/* General Information */}
              {/* <div className='py-2.5'> */}
              {/*  <Text size={'bodyBold'} className=''> */}
              {/*    {t('bal.gen')} */}
              {/*  </Text> */}
              {/*  <RowContainer> */}
              {/*    {[ */}
              {/*      { */}
              {/*        label: t('bal.mar'), */}
              {/*        value: */}
              {/*          loanData?.data?.application_header?.marital_status === 'married' */}
              {/*            ? loanData?.data?.balance_sheet_general_information.marital_regime */}
              {/*            : 'Not Applicable', */}
              {/*        isCurrency: false */}
              {/*      }, */}
              {/*      { */}
              {/*        label: t('bal.bs'), */}
              {/*        value: moment( */}
              {/*          loanData?.data?.balance_sheet_general_information.balance_sheet_date */}
              {/*        ).format('DD/MM/YYYY'), */}
              {/*        isCurrency: false */}
              {/*      } */}
              {/*      // ... add data items here ... */}
              {/*    ].map((item, index) => ( */}
              {/*      <FieldValue */}
              {/*        key={index} */}
              {/*        label={item.label} */}
              {/*        value={item?.value as unknown as string} */}
              {/*        isCurrency={item.isCurrency} */}
              {/*      /> */}
              {/*    ))} */}
              {/*  </RowContainer> */}
              {/* </div> */}
            </div>

            {/* Immovable Assets */}
            <div className={'w-full '}>
              <div className='py-2.5'>
                <Text size={'bodyBold'} className=''>
                  {t('bal.imm')}
                </Text>

                <RowContainer>
                  {loanData?.data?.balance_sheet_immovable_assets?.length !== 0 ? (
                    <>
                      {' '}
                      {immovableAssets?.map((asset, index) => (
                        <FieldValue
                          key={index}
                          label={asset.label}
                          value={asset.value?.toString()}
                          isCurrency={asset.isCurrency}
                        />
                      ))}
                    </>
                  ) : (
                    <Text size='body' className=''>
                      N/A
                    </Text>
                  )}
                </RowContainer>
              </div>
            </div>

            {/* Movable Assets */}
            <div className={'w-full '}>
              <div className='py-2.5'>
                <Text size={'bodyBold'} className=''>
                  {t('bal.mov')}
                </Text>
                <RowContainer>
                  {loanData?.data?.balance_sheet_movable_assets != null ? (
                    <>
                      {' '}
                      {[
                        {
                          label: t('bal.cn'),
                          value: loanData?.data?.balance_sheet_movable_assets?.company_name,
                          isCurrency: false
                        },
                        {
                          label: t('bal.nsc'),
                          value: loanData?.data?.balance_sheet_movable_assets?.number_of_shares,
                          isCurrency: false
                        },
                        {
                          label: t('bal.evs'),
                          value:
                            loanData?.data?.balance_sheet_movable_assets?.estimated_share_value,
                          isCurrency: true
                        },
                        {
                          label: t('bal.tns'),
                          value:
                            loanData?.data?.balance_sheet_movable_assets?.total_number_of_shares,
                          isCurrency: false
                        },
                        {
                          label: t('bal.type'),
                          value: loanData?.data?.balance_sheet_movable_assets?.type_of_assets,
                          isCurrency: false
                        },
                        {
                          label: t('bal.ay'),
                          value: moment(
                            loanData?.data?.balance_sheet_movable_assets?.year_of_acquisition
                          ).format('YYYY'),
                          isCurrency: false
                        },
                        {
                          label: t('bal.nbp'),
                          value: loanData?.data?.balance_sheet_movable_assets?.net_book_value,
                          isCurrency: true
                        },
                        {
                          label: t('bal.est'),
                          value:
                            loanData?.data?.balance_sheet_movable_assets?.estimated_share_value,
                          isCurrency: true
                        },
                        {
                          label: t('bal.rec'),
                          value: loanData?.data?.balance_sheet_movable_assets?.recievables,
                          isCurrency: true
                        },
                        {
                          label: t('bal.tc'),
                          value: loanData?.data?.balance_sheet_movable_assets?.total_cash,
                          isCurrency: true
                        },
                        {
                          label: t('bal.fd'),
                          value: loanData?.data?.balance_sheet_movable_assets?.fixed_deposits,
                          isCurrency: true
                        },
                        {
                          label: t('bal.oa'),
                          value: loanData?.data?.balance_sheet_movable_assets?.other_assets,
                          isCurrency: true
                        }
                      ].map((item, index) => (
                        <FieldValue
                          key={index}
                          label={item.label}
                          value={item?.value as unknown as string}
                          isCurrency={item.isCurrency}
                        />
                      ))}
                      <Text size={'bodyBold'} className=''>
                        Livestock Information
                      </Text>
                      {/* Livestock Data */}
                      {loanData?.data?.livestock_information.length !== 0 ? (
                        <>
                          {livestockInformation?.map((info, index) => (
                            <FieldValue
                              key={index}
                              label={info.label}
                              value={info?.value?.toString()}
                              isCurrency={info.isCurrency}
                            />
                          ))}
                        </>
                      ) : (
                        <Text size='body' className=''>
                          N/A
                        </Text>
                      )}
                    </>
                  ) : (
                    <Text size='body' className=''>
                      N/A
                    </Text>
                  )}
                </RowContainer>
              </div>
            </div>

            {/* Liabilities */}
            <div className={'w-full '}>
              <div className='py-2.5'>
                <Text size={'bodyBold'} className=''>
                  {t('bal.li')}
                </Text>
                <RowContainer>
                  {loanData?.data?.balance_sheet_liabilities != null ? (
                    <>
                      {' '}
                      {[
                        {
                          label: t('bal.lsm'),
                          value: loanData?.data?.balance_sheet_liabilities[0]?.mortgage_loans,
                          isCurrency: true
                        },
                        {
                          label: t('bal.lhp'),
                          value: loanData?.data?.balance_sheet_liabilities[0]?.hire_purchase_loans,
                          isCurrency: true
                        },
                        {
                          label: t('bal.tax'),
                          value: loanData?.data?.balance_sheet_liabilities[0]?.tax_liabilities,
                          isCurrency: true
                        },
                        {
                          label: t('bal.pl'),
                          value: loanData?.data?.balance_sheet_liabilities[0]?.personal_loans,
                          isCurrency: true
                        },
                        {
                          label: t('bal.cred'),
                          value: loanData?.data?.balance_sheet_liabilities[0]?.creditors,
                          isCurrency: true
                        },
                        {
                          label: t('bal.ol'),
                          value: loanData?.data?.balance_sheet_liabilities[0]?.other_liabilities,
                          isCurrency: true
                        }
                        // ... add data items here ...
                      ].map((item, index) => (
                        <FieldValue
                          key={index}
                          label={item.label}
                          value={item?.value as unknown as string}
                          isCurrency={item.isCurrency}
                        />
                      ))}
                    </>
                  ) : (
                    <Text size='body' className=''>
                      N/A
                    </Text>
                  )}
                </RowContainer>
              </div>
            </div>
          </div>
        )}
        <LoanStepperFooter
          submit={handleDownloadPdf}
          isFirstStep={cancel !== undefined}
          cancel={cancel}
        />
      </div>
    </Box>
  )
}
