import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { type OmangDetailsInput } from '../components/OmangDetails'
import { useToast } from 'components/ui'
import { type ValidateOmangDTO, verifyMerchantOmang } from '../api/verifyOmang'
import { updateMerchantKyc, type UpdateMerchantKycDTO } from '../api/updateMerchant'
import type { ApiResponse } from '../../../../types'
import { useMerchantStore } from '../../../../stores/useMerchantStore'

interface UseMerchantKyc {
  isLoading: boolean
  verifyOmangDetails: (data: OmangDetailsInput) => void
}

export const useMerchantKyc = (): UseMerchantKyc => {
  const { merchant } = useMerchantStore()
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const verifyMerchantOmangMutation = useMutation({
    mutationFn: async (data: ValidateOmangDTO) => {
      return verifyMerchantOmang(data)
    }
  })

  const updateMerchantMutation = useMutation({
    mutationFn: async (data: UpdateMerchantKycDTO) => {
      return updateMerchantKyc(data)
    }
  })

  const verifyOmangDetails = (data: OmangDetailsInput): void => {
    verifyMerchantOmangMutation.mutate(
      {
        DOB: dayjs(data.dateOfBirth).format('YYYY-MM-DD'),
        EXPIRY_DATE: dayjs(data.expiryDate).format('YYYY-MM-DD'),
        FORENAMES: data.forenames.toUpperCase(),
        GENDER: data.gender,
        OMANG: data.omang,
        PLACE_OF_BIRTH: data.placeOfBirth.toUpperCase(),
        SURNAME: data.surname.toUpperCase()
      },
      {
        onSuccess: (result) => {
          if (result.status === 200) {
            updateMerchantMutation.mutate(
              {
                user_role: merchant?.user_role,
                uin: merchant?.uin,
                merchant_name: merchant?.merchant_name,
                trade_name: merchant?.trade_name ?? '',
                email: merchant?.email,
                contact: merchant?.contact,
                last_updated_by: merchant?.user_id,
                merchant_pob: data.placeOfBirth.toUpperCase(),
                merchant_expiry_date: dayjs(data.expiryDate).format('YYYY-MM-DD'),
                merchant_firstname: data.forenames.toUpperCase(),
                merchant_midname: '',
                merchant_surname: data.surname.toUpperCase(),
                merchant_omang: data.omang,
                merchant_dob: dayjs(data.dateOfBirth).format('YYYY-MM-DD'),
                merchant_gender: data.gender,
                merchant_id: merchant?.merchant_id,
                postal_addr: data?.postalAddress,
                physical_addr: data?.physicalAddress
              },
              {
                onSuccess: (resp) => {
                  if (resp.status === 201 || resp.status === 200) {
                    toast({
                      variant: 'success',
                      title: 'Kyc successfully completed.'
                    })
                    void queryClient.invalidateQueries({
                      queryKey: ['merchant', merchant?.user_id, merchant?.user_role]
                    })

                    window.location.reload()
                  }
                },
                onError: (err: unknown) => {
                  if (err instanceof AxiosError) {
                    const error = err.response as AxiosResponse<ApiResponse<[]>>

                    toast({
                      variant: 'destructive',
                      title: 'Uh oh! Something went wrong.',
                      description: error.data.message
                    })
                  }
                }
              }
            )
          } else if (result.status === 204) {
            toast({
              variant: 'destructive',
              description: result.message
            })
          } else {
            const err = result.message
            toast({
              variant: 'destructive',
              description: err
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err?.response?.status === 424) {
              toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.',
                description: 'Omang verification is currently unavailable. Please try again later'
              })
            } else {
              toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.'
              })
            }
          } else {
            const er = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: er.message
            })
          }
        }
      }
    )
  }

  return {
    isLoading: verifyMerchantOmangMutation.isLoading || updateMerchantMutation.isLoading,
    verifyOmangDetails
  }
}
