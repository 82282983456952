import type React from 'react'
import { useState } from 'react'
import { CustomAccordion } from 'features/loan-management/components/stepper'
import z, { type TypeOf } from 'zod'
import { useFieldArray, useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import dayjs from 'dayjs'
import { YearPicker } from '../../../../../components/Form/YearPicker'
import { useIndividualLoan } from '../../../hooks/useIndividualLoan'
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  toast,
  useStepper
} from '../../../../../components/ui'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '../../../../../components/App/Layout'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { cn } from '../../../../../lib/utils'
import { format } from 'date-fns'
import { CalendarIcon, Info } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { CustomCheckBoxGroup } from '../../generic/form'

export const schemaBS = z
  .object({
    hasImmovableAssets: z.enum(['yes', 'no']),
    hasMovableAssets: z.enum(['yes', 'no']),
    hasLiabilities: z.enum(['yes', 'no']),
    hasLivestock: z.enum(['yes', 'no']).optional(),
    immovableAssets: z
      .array(
        z.object({
          plot_number: z.string().min(1, 'Plot number is required'),
          property_location: z.string().min(1, 'Property location is required'),
          type_of_land: z.string().min(1, 'Type of land is required'),
          property_size: z.coerce.number({
            required_error: 'Property size is required',
            invalid_type_error: 'Invalid property size'
          }),
          remaining_lease_period: z.coerce.number({
            required_error: 'Remaining lease period is required',
            invalid_type_error: 'Invalid lease period'
          }),
          estimated_value: z.coerce.number({
            required_error: 'Estimated value is required',
            invalid_type_error: 'Invalid estimated value'
          })
        })
      )
      .optional(),
    livestock: z
      .array(
        z.object({
          livestock_number: z.coerce.number({
            required_error: 'Livestock number is required',
            invalid_type_error: 'Invalid livestock number'
          }),
          livestock_id: z.enum(['bulls', 'heifers', 'tollies', 'goat', 'sheep'])
        })
      )
      .optional(),
    loansMortgage: z.coerce.number().positive('Mortgage loans must be greater than 0').optional(),
    loansHirePurchase: z.coerce
      .number()
      .positive('Hire purchase loans must be greater than 0')
      .optional(),
    taxLiabilities: z.coerce.number().positive('Tax liabilities must be greater than 0').optional(),
    personalLoans: z.coerce.number().positive('Personal loans must be greater than 0').optional(),
    creditors: z.coerce.number().positive('Creditors must be greater than 0').optional(),
    otherLiabilities: z.coerce
      .number()
      .positive('Other liabilities must be greater than 0')
      .optional(),
    // General fields
    balanceSheetdDate: z.coerce.date({
      required_error: 'Balance sheet date is required',
      invalid_type_error: 'Invalid balance sheet date'
    }),
    propertyId: z.coerce.number().optional(),
    nameOfTheCompany: z.string().min(1, 'Company name is required').optional(),
    numberOfShares: z.coerce
      .number()
      .positive('Number of shares must be greater than 0')
      .optional(),
    estimatedValueOfShares: z.coerce.number().optional(),
    totalNumbersOfShares: z.coerce.number().optional(),
    yearOfAcquisition: z.coerce
      .date({
        required_error: 'Year of acquisition is required',
        invalid_type_error: 'Invalid year of acquisition'
      })
      .optional(),
    typeOfAsserts: z.string().min(1, 'Type of assets is required').optional(),
    marital_regime: z.string().min(1, 'Marital regime is required'),
    netBookValue: z.coerce.number().optional(),
    estimatedVal: z.coerce.number().optional(),
    debtors: z.coerce.number().optional(),
    cash: z.coerce.number().optional(),
    livestock_number: z.coerce.number().optional(),
    fixedDeposits: z.coerce.number().optional(),
    otherAssets: z.coerce.number().optional()
  })
  .refine(
    (data) => {
      if (data.hasImmovableAssets === 'yes') {
        return data.immovableAssets && data.immovableAssets.length > 0
      }
      return true
    },
    { message: 'Please provide immovable assets details', path: ['immovableAssets'] }
  )
  .refine(
    (data) => {
      if (
        data.hasLivestock === 'yes' &&
        data.livestock_number &&
        data.livestock_number > 0 &&
        data.hasMovableAssets === 'yes'
      ) {
        return data.livestock && data.livestock.length > 0
      }
      return true
    },
    { message: 'Please provide livestock details', path: ['livestock'] }
  )
  .refine(
    (data) => {
      if (data.hasLiabilities === 'yes') {
        return (
          data.loansMortgage &&
          data.loansHirePurchase &&
          data.taxLiabilities &&
          data.creditors &&
          data.otherLiabilities
        )
      }
      return true
    },
    { message: 'Please provide liabilities details', path: ['hasLiabilities'] }
  )
  .refine(
    (data) => {
      if (data.hasMovableAssets === 'yes') {
        return (
          data.propertyId &&
          data.nameOfTheCompany &&
          data.totalNumbersOfShares &&
          data.estimatedValueOfShares &&
          data.numberOfShares &&
          data.typeOfAsserts &&
          data.yearOfAcquisition &&
          data.estimatedVal &&
          data.netBookValue &&
          data.debtors &&
          data.cash &&
          data.livestock_number
        )
      }
      return true
    },
    { message: 'Please provide movable assets details', path: ['hasMovableAssets'] }
  )

export type BalanceSheetDetailsInput = TypeOf<typeof schemaBS>

export const BalanceSheets: React.FC<LoanStepperProps> = ({ onComplete, completedSteps }) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const { farmer } = useFarmerStore()
  const { t } = useTranslation('loanManagement')
  const { nextStep } = useStepper()
  const declarationList = [`${t('bal.ihd')}`, `${t('bal.ifd')}`, `${t('bal.iaa')}`]
  const [signature, setSignature] = useState<string>('')
  const { sendToCMS, isLoading } = useIndividualLoan()
  const form = useForm<BalanceSheetDetailsInput>({
    resolver: zodResolver(schemaBS),
    defaultValues: {
      marital_regime: loanData?.balance_sheets?.general_information?.marital_regime,
      immovableAssets: loanData?.balance_sheets?.immovable_assets,
      propertyId: loanData?.balance_sheets?.movable_assets?.movable_property_id,
      nameOfTheCompany: loanData?.balance_sheets?.movable_assets?.company_name,
      numberOfShares: loanData?.balance_sheets?.movable_assets?.number_of_shares,
      estimatedValueOfShares: loanData?.balance_sheets?.movable_assets?.estimated_share_value,
      totalNumbersOfShares: loanData?.balance_sheets?.movable_assets?.total_number_of_shares,
      typeOfAsserts: loanData?.balance_sheets?.movable_assets?.type_of_assets,
      netBookValue: loanData?.balance_sheets?.movable_assets?.net_book_value,
      estimatedVal: loanData?.balance_sheets?.movable_assets?.estimated,
      livestock_number: loanData?.balance_sheets?.movable_assets?.livestock_number,
      livestock: loanData?.balance_sheets?.movable_assets?.livestock,
      debtors: loanData?.balance_sheets?.movable_assets?.recievables,
      cash: loanData?.balance_sheets?.movable_assets?.total_cash,
      fixedDeposits: loanData?.balance_sheets?.movable_assets?.fixed_deposits,
      otherAssets: loanData?.balance_sheets?.movable_assets?.other_assets,
      loansMortgage: loanData?.balance_sheets?.liablities?.mortgage_loans,
      loansHirePurchase: loanData?.balance_sheets?.liablities?.hire_purchase_loans,
      taxLiabilities: loanData?.balance_sheets?.liablities?.tax_liabilities,
      personalLoans: loanData?.balance_sheets?.liablities?.personal_loans,
      creditors: loanData?.balance_sheets?.liablities?.creditors,
      otherLiabilities: loanData?.balance_sheets?.liablities?.other_liabilities,
      balanceSheetdDate:
        dayjs(loanData?.balance_sheets?.general_information?.balance_sheet_date).toDate() ??
        undefined,
      // yearOfAcquisition: dayjs(Date.now()).toDate() ?? undefined,
      hasLivestock: loanData?.balance_sheets?.movable_assets?.livestock != null ? 'yes' : undefined,
      hasLiabilities: loanData?.balance_sheets?.liablities != null ? 'yes' : undefined,
      hasImmovableAssets: loanData?.balance_sheets?.immovable_assets != null ? 'yes' : undefined,
      hasMovableAssets: loanData?.balance_sheets?.movable_assets != null ? 'yes' : undefined
    }
  })
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'immovableAssets'
  })

  const {
    fields: livestockFields,
    append: appendLivestock,
    remove: removeLivestock
  } = useFieldArray({
    control: form.control,
    name: 'livestock'
  })
  const addNewImmovableAsset = (): void => {
    append({
      plot_number: '',
      property_location: '',
      type_of_land: '',
      property_size: NaN,
      remaining_lease_period: NaN,
      estimated_value: NaN
    })
  }
  const addNewLivestock = (): void => {
    appendLivestock({ livestock_number: NaN, livestock_id: 'goat' })
  }

  const [checkedCheckboxes, setCheckedCheckboxes] = useState<boolean[]>(
    new Array(declarationList.length).fill(false)
  )
  const handleCheckboxChange = (index: number): void => {
    const updatedCheckboxes = [...checkedCheckboxes]
    updatedCheckboxes[index] = !updatedCheckboxes[index]
    setCheckedCheckboxes(updatedCheckboxes)
  }

  const isContinueButtonDisabled = !checkedCheckboxes.every((isChecked: boolean) => isChecked)

  const handleSetSignature = (newSignature: string): void => {
    setSignature(newSignature)
  }

  const onSubmit = (data: BalanceSheetDetailsInput): void => {
    setLoanData((prev) => ({
      ...prev!,
      balance_sheets: {
        general_information: {
          marital_regime: data.marital_regime,
          balance_sheet_date: data.balanceSheetdDate
        },
        immovable_assets: data.hasImmovableAssets === 'yes' ? data.immovableAssets : [],
        ...((data.hasMovableAssets === 'yes' ||
          loanData?.balance_sheets?.movable_assets != null) && {
          movable_assets: {
            movable_property_id: data.propertyId ?? 0,
            company_name: data.nameOfTheCompany ?? '',
            number_of_shares: data.numberOfShares ?? 0,
            estimated_share_value: data.estimatedValueOfShares ?? 0,
            total_number_of_shares: data.totalNumbersOfShares ?? 0,
            type_of_assets: data.typeOfAsserts ?? '',
            year_of_acquisition: data.yearOfAcquisition ?? undefined,
            net_book_value: data.netBookValue ?? 0,
            estimated: data.estimatedVal ?? 0,
            livestock_number: data.livestock_number ?? 0,
            livestock: data.hasLivestock === 'yes' ? data.livestock : undefined,
            recievables: data.debtors ?? 0,
            total_cash: data.cash ?? 0,
            fixed_deposits: data.fixedDeposits ?? 0,
            other_assets: data.otherAssets ?? 0
          }
        }),

        ...((data.hasLiabilities === 'yes' || loanData?.balance_sheets?.liablities != null) && {
          liablities: {
            mortgage_loans: data.loansMortgage ?? 0,
            hire_purchase_loans: data.loansHirePurchase ?? 0,
            tax_liabilities: data.taxLiabilities ?? 0,
            personal_loans: data.personalLoans ?? 0,
            creditors: data.creditors ?? 0,
            other_liabilities: data.otherLiabilities ?? 0
          }
        }),

        declaration: {
          plot_number: '',
          location: '',
          type_of_land: '',
          size: 0,
          remaining_lease_period: 0,
          estimated_value: 0
        }
      },
      last_update_by: Number(farmer?.user_id)
    }))

    if (completedSteps != null && !Object.values(completedSteps).slice(0, 12).every(Boolean)) {
      const firstIncompleteStep = Object.keys(completedSteps)
        .map(Number)
        .slice(0, 13)
        .find((stepIndex) => !completedSteps[stepIndex])
      toast({
        variant: 'warning',
        title: `Please complete step(s): ${
          firstIncompleteStep != null ? firstIncompleteStep + 1 : ''
        }`
      })
    } else {
      if (loanData?.balance_sheets != null) {
        sendToCMS(nextStep, onComplete)
      }
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <div className='flex flex-col gap-3'>
              <Text size={'medium'} variant={'bodyTextLight'} className={''}>
                {t('bal.bal')}
              </Text>
              <div className='flex items-center'>
                <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                <Text variant='secondary' size='small' className=''>
                  Give us a detailed breakdown of your applicable assets and liabilities.
                </Text>
              </div>
            </div>
            <div className={'w-full '}>
              <CustomAccordion
                sections={[
                  {
                    label: t('bal.gen'),
                    fields: ['marital_regime', 'balanceSheetdDate'],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        <FormField
                          control={form.control}
                          name='marital_regime'
                          render={({ field: lowField }) => (
                            <FormItem className='space-y-3 mb-8'>
                              <FormLabel className={'font-redHat'}>
                                <Text size='bodyBold' className=''>
                                  {t('bal.mar')}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <RadioGroup
                                  onValueChange={lowField.onChange}
                                  {...lowField}
                                  className='flex flex-col space-y-1'
                                >
                                  <FormItem className='flex items-center space-x-3 space-y-0'>
                                    <FormControl>
                                      <RadioGroupItem value={'yes'} />
                                    </FormControl>
                                    <FormLabel className={'font-redHat'}>
                                      <Text size='body' className=''>
                                        {t('bal.yes')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className='flex items-center space-x-3 space-y-0'>
                                    <FormControl>
                                      <RadioGroupItem value={'no'} />
                                    </FormControl>
                                    <FormLabel className={'font-redHat'}>
                                      <Text size='body' className=''>
                                        {t('bal.no')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                </RadioGroup>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='balanceSheetdDate'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel>{t('bal.date')}</FormLabel>
                              <Popover>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant={'outlined'}
                                      className={cn(
                                        'text-left font-normal',
                                        field.value != null && 'text-muted-foreground'
                                      )}
                                    >
                                      {field.value != null ? (
                                        format(field.value, 'PPP')
                                      ) : (
                                        <span>{t('bal.dat')}</span>
                                      )}
                                      <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent className='w-auto p-0' align='start'>
                                  <Calendar
                                    mode='single'
                                    captionLayout={'dropdown-buttons'}
                                    toYear={dayjs(new Date(Date.now())).toDate().getFullYear() + 1}
                                    fromYear={
                                      dayjs(new Date(Date.now())).toDate().getFullYear() - 120
                                    }
                                    selected={field.value}
                                    onSelect={field.onChange}
                                    disabled={(date) => new Date(Date.now()) <= date}
                                    initialFocus
                                  />
                                </PopoverContent>
                              </Popover>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    ]
                  },
                  {
                    label: 'assets: immovable property',
                    fields: ['immovableAssets', 'hasImmovableAssets'],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className={'p-2 '}>
                        <FormField
                          control={form.control}
                          name='hasImmovableAssets'
                          render={({ field }) => (
                            <FormItem className='space-y-3'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  Do you have immovable assets?
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <RadioGroup
                                  onValueChange={field.onChange}
                                  {...field}
                                  className='flex flex-col space-y-1'
                                >
                                  <FormItem className='flex items-center space-x-3 space-y-0'>
                                    <FormControl>
                                      <RadioGroupItem value={'yes'} />
                                    </FormControl>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('part.yes')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className='flex items-center space-x-3 space-y-0'>
                                    <FormControl>
                                      <RadioGroupItem value={'no'} />
                                    </FormControl>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('part.no')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                </RadioGroup>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        {(form.watch('hasImmovableAssets') === 'yes' ||
                          loanData?.balance_sheets?.immovable_assets != null) && (
                          <>
                            <div className='w-full flex justify-between '>
                              <div className='flex items-center'>
                                <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                                <Text variant='secondary' size='small' className=''>
                                  Press 'Add New' to add your immovable assets
                                </Text>
                              </div>
                              <Button
                                variant='secondary'
                                size={'xs'}
                                className='w-32'
                                onClick={addNewImmovableAsset}
                              >
                                {t('bal.new')}
                              </Button>
                            </div>
                            <FormField
                              control={form.control}
                              name={`immovableAssets`}
                              render={() => (
                                <FormItem className={'w-full mb-8'}>
                                  {fields.map((field, index) => (
                                    <>
                                      <div className='flex items-center mb-8'>
                                        <FormLabel className={'font-redHat'}>
                                          <Text size={'bodyBold'} className={''}>{`${t(
                                            'bal.imm'
                                          )} ${index + 1}`}</Text>
                                        </FormLabel>
                                      </div>
                                      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                                        <div className='pt-0 md:pt-5'>
                                          <FormField
                                            control={form.control}
                                            name={`immovableAssets.${index}.plot_number`}
                                            render={({ field: lwoField }) => (
                                              <FormItem className='flex flex-col'>
                                                <FormLabel className='font-redHat'>
                                                  <Text size='body' className=''>
                                                    {t('bal.plotNo')}
                                                  </Text>
                                                </FormLabel>
                                                <FormControl>
                                                  <Input
                                                    classLabel='bg-background-dark sm:bg-card'
                                                    placeholder={`${t('bal.plotNo')}`}
                                                    {...lwoField}
                                                    type='text'
                                                    value={lwoField.value ?? undefined}
                                                  />
                                                </FormControl>
                                                <FormMessage />
                                              </FormItem>
                                            )}
                                          />
                                        </div>
                                        <div className='pt-0 md:pt-5'>
                                          <FormField
                                            control={form.control}
                                            name={`immovableAssets.${index}.property_location`}
                                            render={({ field: lwoField }) => (
                                              <FormItem className='flex flex-col'>
                                                <FormLabel className='font-redHat'>
                                                  <Text size='body' className=''>
                                                    {t('bal.loc')}
                                                  </Text>
                                                </FormLabel>
                                                <FormControl>
                                                  <Input
                                                    classLabel='bg-background-dark sm:bg-card'
                                                    placeholder={`${t('bal.loc')}`}
                                                    {...lwoField}
                                                    type='text'
                                                    value={lwoField.value ?? undefined}
                                                  />
                                                </FormControl>
                                                <FormMessage />
                                              </FormItem>
                                            )}
                                          />
                                        </div>
                                        <FormField
                                          control={form.control}
                                          name={`immovableAssets.${index}.type_of_land`}
                                          render={({ field: lwoField }) => (
                                            <FormItem>
                                              <FormLabel className={'font-redHat'}>
                                                <Text size='body' className=''>
                                                  {t('bal.type')}
                                                </Text>
                                              </FormLabel>
                                              <Select
                                                onValueChange={(val) => {
                                                  lwoField.onChange(val)
                                                }}
                                                {...lwoField}
                                                value={lwoField.value ?? undefined}
                                              >
                                                <FormControl>
                                                  <SelectTrigger>
                                                    <SelectValue placeholder={t('bal.typeP')} />
                                                  </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                  <SelectItem value={'commercial'}>
                                                    {t('bal.comm')}
                                                  </SelectItem>
                                                  <SelectItem value={'residential'}>
                                                    {t('bal.res')}
                                                  </SelectItem>
                                                  <SelectItem value={'industrial'}>
                                                    {t('bal.ind')}
                                                  </SelectItem>
                                                  <SelectItem value={'agricultural'}>
                                                    {t('bal.ag')}
                                                  </SelectItem>
                                                </SelectContent>
                                              </Select>
                                              <FormMessage />
                                            </FormItem>
                                          )}
                                        />

                                        <div className='pt-4'>
                                          <FormField
                                            control={form.control}
                                            name={`immovableAssets.${index}.property_size`}
                                            render={({ field: lwoField }) => (
                                              <>
                                                <FormItem className='flex flex-col'>
                                                  <FormLabel className={'font-redHat'}>
                                                    <Text size='body' className=''>
                                                      {t('bal.sz')}
                                                    </Text>
                                                  </FormLabel>
                                                  <FormControl>
                                                    <Input
                                                      classLabel={'bg-background-dark sm:bg-card'}
                                                      placeholder={t('bal.szp')}
                                                      type={'number'}
                                                      inputMode={'numeric'}
                                                      {...lwoField}
                                                      value={lwoField.value ?? undefined}
                                                    />
                                                  </FormControl>
                                                  <FormMessage />
                                                </FormItem>
                                              </>
                                            )}
                                          />
                                        </div>
                                        <div className='pt-4'>
                                          <FormField
                                            control={form.control}
                                            name={`immovableAssets.${index}.remaining_lease_period`}
                                            render={({ field: lwoField }) => (
                                              <>
                                                <FormItem className='flex flex-col'>
                                                  <FormLabel className={'font-redHat'}>
                                                    <Text size='body' className=''>
                                                      {t('bal.rlp')}
                                                    </Text>
                                                  </FormLabel>
                                                  <FormControl>
                                                    <Input
                                                      classLabel={'bg-background-dark sm:bg-card'}
                                                      placeholder={t('bal.rlp')}
                                                      type={'number'}
                                                      inputMode={'numeric'}
                                                      {...lwoField}
                                                      value={lwoField.value ?? undefined}
                                                    />
                                                  </FormControl>
                                                  <FormMessage />
                                                </FormItem>
                                              </>
                                            )}
                                          />
                                        </div>

                                        <div className='pt-2'>
                                          <FormField
                                            control={form.control}
                                            name={`immovableAssets.${index}.estimated_value`}
                                            render={({ field: lwoField }) => (
                                              <>
                                                <FormItem className='flex flex-col'>
                                                  <FormLabel className={'font-redHat'}>
                                                    <Text size='body' className=''>
                                                      {t('bal.ev')} (BWP)
                                                    </Text>
                                                  </FormLabel>
                                                  <FormControl>
                                                    <div className='relative'>
                                                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                                        <Text size='bodyBold' className=''>
                                                          BWP
                                                        </Text>
                                                      </div>
                                                      <Input
                                                        id='currency'
                                                        inputMode='decimal'
                                                        type='number'
                                                        min={0}
                                                        step={0.01}
                                                        placeholder='0.00'
                                                        className='pl-14'
                                                        {...lwoField}
                                                        value={lwoField.value ?? undefined}
                                                      />
                                                    </div>
                                                  </FormControl>
                                                  <FormMessage />
                                                </FormItem>
                                              </>
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div className='w-full flex justify-end '>
                                        <Button
                                          color='error'
                                          variant={'secondary'}
                                          size={'xs'}
                                          onClick={() => {
                                            remove(index)
                                          }}
                                          disabled={index === 0}
                                        >
                                          Remove
                                        </Button>
                                      </div>
                                    </>
                                  ))}
                                </FormItem>
                              )}
                            />{' '}
                          </>
                        )}
                      </div>
                    ]
                  },
                  {
                    label: 'assets: movable property',
                    fields: [
                      'propertyId',
                      'nameOfTheCompany',
                      'numberOfShares',
                      'estimatedValueOfShares',
                      'totalNumbersOfShares',
                      'typeOfAsserts',
                      'yearOfAcquisition',
                      'netBookValue',
                      'estimatedVal',
                      'debtors',
                      'cash',
                      'fixedDeposits',
                      'otherAssets',
                      'livestock_number',
                      'livestock',
                      'hasMovableAssets',
                      'hasLivestock'
                    ],
                    fieldErrors: form.formState.errors,
                    children: [
                      <>
                        <FormField
                          control={form.control}
                          name='hasMovableAssets'
                          render={({ field }) => (
                            <FormItem className='space-y-3'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  Do you have movable assets?
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <RadioGroup
                                  onValueChange={field.onChange}
                                  {...field}
                                  className='flex flex-col space-y-1'
                                >
                                  <FormItem className='flex items-center space-x-3 space-y-0'>
                                    <FormControl>
                                      <RadioGroupItem value={'yes'} />
                                    </FormControl>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('part.yes')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className='flex items-center space-x-3 space-y-0'>
                                    <FormControl>
                                      <RadioGroupItem value={'no'} />
                                    </FormControl>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('part.no')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                </RadioGroup>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        {(form.watch('hasMovableAssets') === 'yes' ||
                          loanData?.balance_sheets?.movable_assets != null) && (
                          <>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-6'>
                              <FormField
                                control={form.control}
                                name='propertyId'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.mpi')}
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <Input
                                        classLabel='bg-background-dark sm:bg-card'
                                        placeholder={t('bal.mpi')}
                                        {...field}
                                        type='text'
                                        value={field.value ?? undefined}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={form.control}
                                name='nameOfTheCompany'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.nc')}
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <Input
                                        classLabel='bg-background-dark sm:bg-card'
                                        placeholder={t('bal.cn')}
                                        {...field}
                                        type='text'
                                        value={field.value ?? undefined}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />

                              <FormField
                                control={form.control}
                                name='numberOfShares'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.nsc')}
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <Input
                                        classLabel='bg-background-dark sm:bg-card'
                                        placeholder={t('bal.nsc')}
                                        {...field}
                                        inputMode={'numeric'}
                                        type='number'
                                        value={field.value ?? undefined}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={form.control}
                                name='estimatedValueOfShares'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.evs')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <div className='pt-0 md:pt-2'>
                                <FormField
                                  control={form.control}
                                  name='totalNumbersOfShares'
                                  render={({ field }) => (
                                    <FormItem className='flex flex-col'>
                                      <FormLabel className='font-redHat'>
                                        <Text size='body' className=''>
                                          {t('bal.tns')}
                                        </Text>
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          classLabel='bg-background-dark sm:bg-card'
                                          placeholder={t('bal.tns')}
                                          {...field}
                                          inputMode={'numeric'}
                                          type='number'
                                          value={field.value ?? undefined}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              </div>
                              <div className='pt-0 md:pt-2'>
                                <FormField
                                  control={form.control}
                                  name='typeOfAsserts'
                                  render={({ field }) => (
                                    <FormItem className='flex flex-col'>
                                      <FormLabel className='font-redHat'>
                                        <Text size='body' className=''>
                                          {t('bal.ta')}
                                        </Text>
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          classLabel='bg-background-dark sm:bg-card'
                                          placeholder={t('bal.ta')}
                                          {...field}
                                          type='text'
                                          value={field.value ?? undefined}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              </div>

                              <div className='flex flex-col mt-3 md:mt-9 '>
                                <YearPicker
                                  label={t('bal.ay')}
                                  defaultValue={
                                    loanData?.balance_sheets?.movable_assets
                                      ?.year_of_acquisition === undefined
                                      ? undefined
                                      : dayjs(
                                          loanData.balance_sheets.movable_assets.year_of_acquisition
                                        )
                                  }
                                  error={!(form.formState.errors.yearOfAcquisition == null)}
                                  helperText={
                                    form.formState.errors.yearOfAcquisition?.message ?? ''
                                  }
                                  name={'yearOfAcquisition'}
                                  control={form.control}
                                  className={'capitalize'}
                                />
                              </div>

                              <div className='mt-2'>
                                <FormField
                                  control={form.control}
                                  name='netBookValue'
                                  render={({ field }) => (
                                    <FormItem className='flex flex-col'>
                                      <FormLabel className='font-redHat'>
                                        <Text size='body' className=''>
                                          {t('bal.nbp')} (BWP)
                                        </Text>
                                      </FormLabel>
                                      <FormControl>
                                        <div className='relative'>
                                          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                            <Text size='bodyBold' className=''>
                                              BWP
                                            </Text>
                                          </div>
                                          <Input
                                            id='currency'
                                            inputMode='decimal'
                                            type='number'
                                            min={0}
                                            step={0.01}
                                            placeholder='0.00'
                                            className='pl-14'
                                            {...field}
                                            value={field.value ?? undefined}
                                          />
                                        </div>
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              </div>

                              <div className='mt-2'>
                                <FormField
                                  control={form.control}
                                  name='estimatedVal'
                                  render={({ field }) => (
                                    <FormItem className='flex flex-col'>
                                      <FormLabel className='font-redHat'>
                                        <Text size='body' className=''>
                                          {t('bal.ev')}(BWP)
                                        </Text>
                                      </FormLabel>
                                      <FormControl>
                                        <div className='relative'>
                                          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                            <Text size='bodyBold' className=''>
                                              BWP
                                            </Text>
                                          </div>
                                          <Input
                                            id='currency'
                                            inputMode='decimal'
                                            type='number'
                                            min={0}
                                            step={0.01}
                                            placeholder='0.00'
                                            className='pl-14'
                                            {...field}
                                            value={field.value ?? undefined}
                                          />
                                        </div>
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              </div>

                              <div className='mt-2'>
                                <FormField
                                  control={form.control}
                                  name='debtors'
                                  render={({ field }) => (
                                    <FormItem className='flex flex-col'>
                                      <FormLabel className='font-redHat'>
                                        <Text size='body' className=''>
                                          {t('bal.dr')}
                                        </Text>
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          classLabel='bg-background-dark sm:bg-card'
                                          placeholder={t('bal.dr')}
                                          {...field}
                                          inputMode={'numeric'}
                                          type='number'
                                          value={field.value ?? undefined}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              </div>

                              <FormField
                                control={form.control}
                                name='cash'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.cab')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={form.control}
                                name='fixedDeposits'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.fdb')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={form.control}
                                name='otherAssets'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.oa')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <FormField
                              control={form.control}
                              name='hasLivestock'
                              render={({ field }) => (
                                <FormItem className='space-y-3'>
                                  <FormLabel className='font-redHat'>
                                    <Text size='body' className=''>
                                      Do you have Livestock?
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <RadioGroup
                                      onValueChange={field.onChange}
                                      {...field}
                                      className='flex flex-col space-y-1'
                                    >
                                      <FormItem className='flex items-center space-x-3 space-y-0'>
                                        <FormControl>
                                          <RadioGroupItem value={'yes'} />
                                        </FormControl>
                                        <FormLabel className='font-redHat'>
                                          <Text size='body' className=''>
                                            {t('part.yes')}
                                          </Text>
                                        </FormLabel>
                                      </FormItem>
                                      <FormItem className='flex items-center space-x-3 space-y-0'>
                                        <FormControl>
                                          <RadioGroupItem value={'no'} />
                                        </FormControl>
                                        <FormLabel className='font-redHat'>
                                          <Text size='body' className=''>
                                            {t('part.no')}
                                          </Text>
                                        </FormLabel>
                                      </FormItem>
                                    </RadioGroup>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />

                            {(form.watch('hasLivestock') === 'yes' ||
                              (loanData?.balance_sheets?.movable_assets?.livestock_number != null &&
                                loanData?.balance_sheets?.movable_assets?.livestock != null)) && (
                              <div className='mt-5'>
                                <div>
                                  <FormItem className='flex flex-col mb-4'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='bodyBold' className=''>
                                        {t('bal.lv')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                </div>

                                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 '>
                                  <FormField
                                    control={form.control}
                                    name='livestock_number'
                                    render={({ field }) => (
                                      <FormItem className='flex flex-col '>
                                        <FormLabel className='font-redHat'>
                                          <Text size='body' className=''>
                                            {t('bal.tlv')}
                                          </Text>
                                        </FormLabel>
                                        <FormControl>
                                          <Input
                                            classLabel='bg-background-dark sm:bg-card'
                                            placeholder={t('bal.tlvp')}
                                            {...field}
                                            inputMode={'numeric'}
                                            type='number'
                                            value={field.value ?? undefined}
                                          />
                                        </FormControl>
                                        <FormMessage />
                                      </FormItem>
                                    )}
                                  />

                                  <Button
                                    variant='secondary'
                                    size={'xs'}
                                    onClick={addNewLivestock}
                                    className={'ml-4 w-32 mt-0 md:mt-5 '}
                                  >
                                    Add Livestock
                                  </Button>
                                </div>

                                <div className='flex my-3'>
                                  <Info size='18' color='orange' className='mr-2.5' />
                                  <Text size='small' className=''>
                                    {t('bal.pal')}
                                  </Text>
                                </div>
                                {/* </div> */}

                                <div className='mt-8'>
                                  <FormField
                                    control={form.control}
                                    name={`immovableAssets`}
                                    render={() => (
                                      <FormItem className={'w-full '}>
                                        {livestockFields.map((field, index) => (
                                          <>
                                            <div className='flex items-center '>
                                              <FormLabel className={'font-redHat'}>
                                                <Text size='bodyBold' className=''>
                                                  {`${t('bal.lv')} ${index + 1}`}
                                                </Text>
                                              </FormLabel>
                                            </div>
                                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                                              <FormField
                                                control={form.control}
                                                name={`livestock.${index}.livestock_id`}
                                                render={({ field: lwoField }) => (
                                                  <FormItem>
                                                    <FormLabel className={'font-redHat'}>
                                                      <Text size='body' className=''>
                                                        {t('bal.lvn')}
                                                      </Text>
                                                    </FormLabel>
                                                    <Select
                                                      onValueChange={(val) => {
                                                        lwoField.onChange(val)
                                                      }}
                                                      {...lwoField}
                                                      value={lwoField.value ?? undefined}
                                                    >
                                                      <FormControl>
                                                        <SelectTrigger>
                                                          <SelectValue placeholder={t('bal.lvn')} />
                                                        </SelectTrigger>
                                                      </FormControl>
                                                      <SelectContent>
                                                        <SelectItem value={'bulls'}>
                                                          {t('bal.bu')}
                                                        </SelectItem>
                                                        <SelectItem value={'heifers'}>
                                                          {t('bal.he')}
                                                        </SelectItem>
                                                        <SelectItem value={'tollies'}>
                                                          {t('bal.to')}
                                                        </SelectItem>
                                                        <SelectItem value={'goat'}>
                                                          {t('bal.go')}
                                                        </SelectItem>
                                                        <SelectItem value={'sheep'}>
                                                          {t('bal.sh')}
                                                        </SelectItem>
                                                      </SelectContent>
                                                    </Select>
                                                    <FormMessage />
                                                  </FormItem>
                                                )}
                                              />

                                              <div className='pt-0 md:pt-5'>
                                                <FormField
                                                  control={form.control}
                                                  name={`livestock.${index}.livestock_number`}
                                                  render={({ field: lwoField }) => (
                                                    <FormItem className='flex flex-col'>
                                                      <FormLabel className={'font-redHat'}>
                                                        <Text size='body' className=''>
                                                          {t('bal.lvno')}
                                                        </Text>
                                                      </FormLabel>
                                                      <FormControl>
                                                        <Input
                                                          classLabel={
                                                            'bg-background-dark sm:bg-card'
                                                          }
                                                          placeholder={t('bal.lvno')}
                                                          type={'number'}
                                                          inputMode={'numeric'}
                                                          {...lwoField}
                                                          value={lwoField.value ?? undefined}
                                                        />
                                                      </FormControl>
                                                      <FormMessage />
                                                    </FormItem>
                                                  )}
                                                />
                                              </div>
                                            </div>
                                            <div className='w-full flex justify-end '>
                                              <Button
                                                color='error'
                                                variant={'secondary'}
                                                size={'xs'}
                                                onClick={() => {
                                                  removeLivestock(index)
                                                }}
                                                disabled={index === 0}
                                              >
                                                {t('bal.remove')}
                                              </Button>
                                            </div>
                                          </>
                                        ))}
                                      </FormItem>
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ]
                  },
                  {
                    label: 'liabilities',
                    fields: [
                      'loansMortgage',
                      'loansHirePurchase',
                      'taxLiabilities',
                      'personalLoans',
                      'creditors',
                      'otherLiabilities',
                      'hasLiabilities'
                    ],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className={'p-2 flex flex-col space-y-8'}>
                        <FormField
                          control={form.control}
                          name='hasLiabilities'
                          render={({ field }) => (
                            <FormItem className='space-y-3'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  Do you have movable assets?
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <RadioGroup
                                  onValueChange={field.onChange}
                                  {...field}
                                  className='flex flex-col space-y-1'
                                >
                                  <FormItem className='flex items-center space-x-3 space-y-0'>
                                    <FormControl>
                                      <RadioGroupItem value={'yes'} />
                                    </FormControl>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('part.yes')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className='flex items-center space-x-3 space-y-0'>
                                    <FormControl>
                                      <RadioGroupItem value={'no'} />
                                    </FormControl>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('part.no')}
                                      </Text>
                                    </FormLabel>
                                  </FormItem>
                                </RadioGroup>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        {(form.watch('hasLiabilities') === 'yes' ||
                          loanData?.balance_sheets?.liablities != null) && (
                          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                            <div className='pt-2'>
                              <FormField
                                control={form.control}
                                name='loansMortgage'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.lsm')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <div className='pt-2'>
                              <FormField
                                control={form.control}
                                name='loansHirePurchase'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.lhp')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <div className='pt-2'>
                              <FormField
                                control={form.control}
                                name='taxLiabilities'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.tax')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>

                            <div className='pt-2'>
                              <FormField
                                control={form.control}
                                name='personalLoans'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.pl')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>

                            <div className='pt-2'>
                              <FormField
                                control={form.control}
                                name='creditors'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.cred')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <div className='pt-2'>
                              <FormField
                                control={form.control}
                                name='otherLiabilities'
                                render={({ field }) => (
                                  <FormItem className='flex flex-col'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>
                                        {t('bal.ol')} (BWP)
                                      </Text>
                                    </FormLabel>
                                    <FormControl>
                                      <div className='relative'>
                                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                          <Text size='bodyBold' className=''>
                                            BWP
                                          </Text>
                                        </div>
                                        <Input
                                          id='currency'
                                          inputMode='decimal'
                                          type='number'
                                          min={0}
                                          step={0.01}
                                          placeholder='0.00'
                                          className='pl-14'
                                          {...field}
                                          value={field.value ?? undefined}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ]
                  },
                  {
                    label: 'Declaration',
                    children: [
                      <div className={'p-2 flex flex-col space-y-2'}>
                        <CustomCheckBoxGroup
                          labels={declarationList}
                          checkedCheckboxes={checkedCheckboxes}
                          onCheckboxChange={handleCheckboxChange}
                        />

                        {/* <SigningPad setSignature={handleSetSignature} /> */}
                      </div>
                    ]
                  }
                ]}
              />
            </div>
          </div>

          <LoanStepperFooter disabled={isContinueButtonDisabled} isLoading={isLoading} />
        </form>
      </Form>
    </Box>
  )
}
