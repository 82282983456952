import { AxiosError, type AxiosResponse } from 'axios'
import { getCroppingPlans } from 'features/officer/cropping-plan/api/getCroppingPlans'
import { useState } from 'react'
import { type ApiResponse, type CroppingPlan } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'

interface UseSearchCroppingPlan {
  isLoading: boolean
  error: string | null
  searchCroppingPlan: (query: string) => Promise<void>
  data: CroppingPlan[] | null
}

export const useSearchCroppingPlan = (
  officerId: number | null | undefined
): UseSearchCroppingPlan => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<CroppingPlan[] | null>(null)

  const searchCroppingPlan = async (query: string): Promise<void> => {
    setData(null)
    setError(null)
    setIsLoading(true)
    try {
      const response = await getCroppingPlans(query, officerId)
      if (response?.status === 200) {
        const croppingPlans = response?.data
        if (croppingPlans?.length === 0) {
          setData([])
        } else {
          setData(croppingPlans)
        }
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<[]>>
        setError(
          mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        )
      } else {
        const errorMsg = err as Error
        setError(mapCmsErrorToMessage(errorMsg.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { error, isLoading, searchCroppingPlan, data }
}
