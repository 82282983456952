import type z from 'zod'
import { array, coerce, object, string, type TypeOf } from 'zod'
import { useFieldArray, useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Text,
  useStepper
} from '../../../../../components/ui'
import { Box } from '../../../../../components/App/Layout'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'
import { scaleType } from './LoanApplicationForm'
import { farmerType } from '../stores'
import { IoIosWarning } from 'react-icons/io'
import { CustomAccordion } from '../../generic/CustomAccordion'
import { Info } from 'lucide-react'
import type React from 'react'

const schema = object({
  immovableProperty: array(
    object({
      age: coerce.number().refine((value) => value <= 5, {
        message: 'Age should be 5 years or less'
      }),
      encumbrances_amount: coerce.number(),
      estimated_forces_sale_value: coerce.number(),
      immovable_property_id: coerce.number(),
      market_value: coerce.number(),
      original_cost: coerce.number()
    })
  ),
  machineryAge: coerce.number().refine((value) => value <= 5, {
    message: 'Age should be 5 years or less'
  }),
  machineryOriginalCost: coerce.number(),
  machineryMarketValue: coerce.number(),
  machineryVehicleEstimatedForcedSaleVal: coerce.number(),
  machineryVehicleAmountEncumb: coerce.number(),
  machineryId: coerce.number(),
  motorVehicleAge: coerce.number().refine((value) => value <= 5, {
    message: 'Age should be 5 years or less'
  }),
  motorVehicleOriginalCost: coerce.number(),
  motorVehicleMarketValue: coerce.number(),
  motorVehicleEstimatedForcedSaleVal: coerce.number(),
  motorVehicleAmountEncumb: coerce.number(),
  motorvehicleId: coerce.number(),
  valueOfGurantee: coerce.number(),
  valueOfOtherGurantee: coerce.number(),
  sumAssured: coerce.number(),
  fixedDeposit: coerce.number(),
  estimations: string()
})

export type SecurityDetailsInput = TypeOf<typeof schema>
// export type SecurityDetailsInput = z.infer<ReturnType<typeof schema>>

export const Security: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const [famerType] = useAtom(farmerType)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')
  const createSchema = (isScaleType: boolean): z.ZodObject<any> => {
    return isScaleType ? schema.partial() : schema
  }
  const schemaS = createSchema(famerType?.farmerType === scaleType)

  const form = useForm<SecurityDetailsInput>({
    resolver: zodResolver(schemaS),
    defaultValues: {
      immovableProperty: loanData?.loan_security?.immovable_property?.map((i) => ({
        age: i.age,
        immovable_property_id: i.immovable_property_id,
        estimated_forces_sale_value: i.estimated_forces_sale_value,
        original_cost: i.original_cost,
        market_value: i.market_value,
        encumbrances_amount: i.encumbrances_amount
      })),
      estimations: loanData?.loan_security?.basis_of_estimation?.basis_of_estimation,
      fixedDeposit: loanData?.loan_security?.loan_other_security?.fixed_deposit_balance,
      machineryAge: loanData?.loan_security?.loan_machinery?.age,
      machineryId: loanData?.loan_security?.loan_machinery?.machinery_id,
      machineryMarketValue: loanData?.loan_security?.loan_machinery?.market_value,
      machineryOriginalCost: loanData?.loan_security?.loan_machinery?.original_cost,
      machineryVehicleAmountEncumb: loanData?.loan_security?.loan_machinery?.encumbrances_amount,
      machineryVehicleEstimatedForcedSaleVal:
        loanData?.loan_security?.loan_machinery?.estimated_forces_sale_value,
      motorVehicleAge: loanData?.loan_security?.loan_motor_vehicle?.age,
      motorVehicleAmountEncumb: loanData?.loan_security?.loan_motor_vehicle?.encumbrances_amount,
      motorVehicleEstimatedForcedSaleVal:
        loanData?.loan_security?.loan_motor_vehicle?.estimated_forces_sale_value,
      motorvehicleId: loanData?.loan_security?.loan_motor_vehicle?.vehicle_id,
      motorVehicleMarketValue: loanData?.loan_security?.loan_motor_vehicle?.market_value,
      motorVehicleOriginalCost: loanData?.loan_security?.loan_motor_vehicle?.original_cost,
      valueOfGurantee: loanData?.loan_security?.loan_bank_guarantee?.guarantee_value,
      valueOfOtherGurantee: loanData?.loan_security?.loan_other_guarantee?.guarantee_value,
      sumAssured: loanData?.loan_security?.loan_insurance_policy?.sum_assured
    }
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'immovableProperty'
  })
  const addNewProperty = (): void => {
    append({
      immovable_property_id: NaN,
      age: NaN,
      estimated_forces_sale_value: NaN,
      original_cost: NaN,
      market_value: NaN,
      encumbrances_amount: NaN
    })
  }

  const onSubmit = (data?: SecurityDetailsInput): void => {
    if (famerType?.farmerType === scaleType) {
      setLoanData((prev) => ({
        ...prev!
      }))
    } else {
      setLoanData((prev) => ({
        ...prev!,
        loan_security: {
          immovable_property: data?.immovableProperty ?? [],
          loan_machinery: {
            age: data?.machineryAge,
            original_cost: data?.machineryOriginalCost,
            market_value: data?.machineryMarketValue,
            estimated_forces_sale_value: data?.machineryVehicleEstimatedForcedSaleVal,
            encumbrances_amount: data?.machineryVehicleAmountEncumb,
            machinery_id: data?.machineryId
          },
          loan_motor_vehicle: {
            age: data?.motorVehicleAge,
            original_cost: data?.motorVehicleOriginalCost,
            market_value: data?.motorVehicleMarketValue,
            estimated_forces_sale_value: data?.motorVehicleEstimatedForcedSaleVal,
            encumbrances_amount: data?.motorVehicleAmountEncumb,
            vehicle_id: data?.motorvehicleId
          },
          loan_bank_guarantee: { guarantee_value: data?.valueOfGurantee },
          loan_other_guarantee: { guarantee_value: data?.valueOfOtherGurantee },
          loan_insurance_policy: { sum_assured: data?.sumAssured },
          loan_other_security: { fixed_deposit_balance: data?.fixedDeposit },
          basis_of_estimation: { basis_of_estimation: data?.estimations }
        }
      }))
    }
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          {famerType?.farmerType === scaleType ? (
            <div className='flex flex-col space-y-8 my-6 w-full'>
              <Text size={'medium'}>{t('sec.sec')}</Text>

              <Card
                className={
                  'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
                }
              >
                <div className='flex'>
                  <IoIosWarning fontSize='20' className='text-amber-600 mr-4' />

                  <div>
                    <Text size='bodyBold' className=' mb-4'>
                      {t('sec.not')}
                    </Text>

                    <Text size='body' className=''>
                      <li> {t('sec.reason')}</li>
                    </Text>
                  </div>
                </div>
              </Card>
            </div>
          ) : (
            <div className='flex flex-col space-y-8 my-6 w-full'>
              <FormLabel className={'font-redHat'}>
                <Text size='medium' className=''>
                  {t('sec.sec')}
                </Text>
              </FormLabel>
              <div className={'w-full'}>
                <CustomAccordion
                  sections={[
                    {
                      label: t('sec.imm'),
                      fields: ['immovableProperty'],
                      fieldErrors: form.formState.errors,
                      children: (
                        <>
                          <div className={'flex items-center justify-between'}>
                            <div className='flex flex-col gap-3'>
                              <div className='flex items-center mb-4'>
                                <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                                <Text variant='secondary' size='small' className=''>
                                  {t('sec.add')}
                                </Text>
                              </div>
                            </div>

                            <Button variant={'primary'} size={'xs'} onClick={addNewProperty}>
                              {t('sec.new')}
                            </Button>
                          </div>

                          <FormField
                            control={form.control}
                            name={`immovableProperty`}
                            render={() => (
                              <FormItem className={'w-full mb-8'}>
                                {fields.map((field, index) => (
                                  <div className='w-full px-2.5'>
                                    <div className='flex items-center mb-5'>
                                      <FormLabel className='font-redHat'>
                                        <Text size='body' className=''>{`${t('sec.pr')} ${
                                          index + 1
                                        }`}</Text>
                                      </FormLabel>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                                      <FormField
                                        control={form.control}
                                        name={`immovableProperty.${index}.immovable_property_id`}
                                        render={({ field: lwoField }) => (
                                          <>
                                            <FormItem className='flex flex-col'>
                                              <FormLabel className={'font-redHat'}>
                                                <Text size='body' className=''>
                                                  {t('sec.pl')}
                                                </Text>
                                              </FormLabel>
                                              <FormControl>
                                                <Input
                                                  classLabel={'bg-background-dark sm:bg-card'}
                                                  placeholder={t('sec.pl')}
                                                  type={'number'}
                                                  inputMode={'numeric'}
                                                  {...lwoField}
                                                />
                                              </FormControl>
                                              <FormMessage />
                                            </FormItem>
                                          </>
                                        )}
                                      />
                                      <FormField
                                        control={form.control}
                                        name={`immovableProperty.${index}.age`}
                                        render={({ field: lwoField }) => (
                                          <>
                                            <FormItem className='flex flex-col'>
                                              <FormLabel className={'font-redHat'}>
                                                <Text size='body' className=''>
                                                  {t('sec.age')}
                                                </Text>
                                              </FormLabel>
                                              <FormControl>
                                                <Input
                                                  classLabel={'bg-background-dark sm:bg-card'}
                                                  placeholder={t('sec.age')}
                                                  type={'number'}
                                                  inputMode={'numeric'}
                                                  {...lwoField}
                                                />
                                              </FormControl>
                                              <FormMessage />
                                            </FormItem>
                                          </>
                                        )}
                                      />
                                      <FormField
                                        control={form.control}
                                        name={`immovableProperty.${index}.original_cost`}
                                        render={({ field: lwoField }) => (
                                          <>
                                            <FormItem className='flex flex-col'>
                                              <FormLabel className={'font-redHat'}>
                                                <Text size='body' className=''>
                                                  {t('sec.oc')}
                                                </Text>
                                              </FormLabel>
                                              <FormControl>
                                                <div className='relative'>
                                                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                                    <Text size='bodyBold' className=''>
                                                      BWP
                                                    </Text>
                                                  </div>
                                                  <Input
                                                    id='currency'
                                                    inputMode='decimal'
                                                    type='number'
                                                    min={0}
                                                    step={0.01}
                                                    placeholder='0.00'
                                                    className='pl-14'
                                                    {...lwoField}
                                                  />
                                                </div>
                                              </FormControl>
                                              <FormMessage />
                                            </FormItem>
                                          </>
                                        )}
                                      />
                                      <FormField
                                        control={form.control}
                                        name={`immovableProperty.${index}.market_value`}
                                        render={({ field: lwoField }) => (
                                          <>
                                            <FormItem className='flex flex-col'>
                                              <FormLabel className={'font-redHat'}>
                                                <Text size='body' className=''>
                                                  {t('sec.mv')}
                                                </Text>
                                              </FormLabel>
                                              <FormControl>
                                                <div className='relative'>
                                                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                                    <Text size='bodyBold' className=''>
                                                      BWP
                                                    </Text>
                                                  </div>
                                                  <Input
                                                    id='currency'
                                                    inputMode='decimal'
                                                    type='number'
                                                    min={0}
                                                    step={0.01}
                                                    placeholder='0.00'
                                                    className='pl-14'
                                                    {...lwoField}
                                                  />
                                                </div>
                                              </FormControl>
                                              <FormMessage />
                                            </FormItem>
                                          </>
                                        )}
                                      />

                                      <FormField
                                        control={form.control}
                                        name={`immovableProperty.${index}.estimated_forces_sale_value`}
                                        render={({ field: lwoField }) => (
                                          <>
                                            <FormItem className='flex flex-col'>
                                              <FormLabel className={'font-redHat'}>
                                                <Text size='body' className=''>
                                                  {t('sec.efs')}
                                                </Text>
                                              </FormLabel>
                                              <FormControl>
                                                <div className='relative'>
                                                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                                    <Text size='bodyBold' className=''>
                                                      BWP
                                                    </Text>
                                                  </div>
                                                  <Input
                                                    id='currency'
                                                    inputMode='decimal'
                                                    type='number'
                                                    min={0}
                                                    step={0.01}
                                                    placeholder='0.00'
                                                    className='pl-14'
                                                    {...lwoField}
                                                  />
                                                </div>
                                              </FormControl>
                                              <FormMessage />
                                            </FormItem>
                                          </>
                                        )}
                                      />

                                      <FormField
                                        control={form.control}
                                        name={`immovableProperty.${index}.encumbrances_amount`}
                                        render={({ field: lwoField }) => (
                                          <>
                                            <FormItem className='flex flex-col'>
                                              <FormLabel className={'font-redHat'}>
                                                <Text size='body' className=''>
                                                  {t('sec.ae')}
                                                </Text>
                                              </FormLabel>
                                              <FormControl>
                                                <div className='relative'>
                                                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                                    <Text size='bodyBold' className=''>
                                                      BWP
                                                    </Text>
                                                  </div>
                                                  <Input
                                                    id='currency'
                                                    inputMode='decimal'
                                                    type='number'
                                                    min={0}
                                                    step={0.01}
                                                    placeholder='0.00'
                                                    className='pl-14'
                                                    {...lwoField}
                                                  />
                                                </div>
                                              </FormControl>
                                              <FormMessage />
                                            </FormItem>
                                          </>
                                        )}
                                      />
                                    </div>
                                    <div className='w-full flex justify-end mt-8'>
                                      <Button
                                        color='error'
                                        variant={'secondary'}
                                        size={'xs'}
                                        onClick={() => {
                                          remove(index)
                                        }}
                                      >
                                        {t('sec.rp')}
                                      </Button>
                                    </div>
                                  </div>
                                ))}
                              </FormItem>
                            )}
                          />
                        </>
                      )
                    },
                    {
                      label: t('sec.me'),
                      fields: [
                        'machineryVehicleAmountEncumb',
                        'machineryId',
                        'machineryAge',
                        'machineryOriginalCost',
                        'machineryMarketValue',
                        'machineryVehicleEstimatedForcedSaleVal'
                      ],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-2.5'>
                          <FormField
                            control={form.control}
                            name='machineryId'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.mid')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    classLabel='bg-background-dark sm:bg-card'
                                    placeholder={t('sec.mid')}
                                    {...field}
                                    type='number'
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name='machineryAge'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.ma')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    classLabel='bg-background-dark sm:bg-card'
                                    placeholder={t('sec.ma')}
                                    {...field}
                                    type='number'
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name='machineryOriginalCost'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.oc')} (BWP)
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name='machineryMarketValue'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.om')} (BWP)
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name='machineryVehicleEstimatedForcedSaleVal'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.efs')}(BWP)
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    classLabel='bg-background-dark sm:bg-card'
                                    placeholder='(BWP)'
                                    {...field}
                                    type='number'
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name='machineryVehicleAmountEncumb'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.ae')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: t('sec.mvc'),
                      fields: [
                        'motorVehicleAmountEncumb',
                        'motorvehicleId',
                        'motorVehicleAge',
                        'motorVehicleOriginalCost',
                        'motorVehicleMarketValue',
                        'motorVehicleEstimatedForcedSaleVal'
                      ],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-2.5'>
                          <FormField
                            control={form.control}
                            name='motorvehicleId'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.mvr')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    classLabel='bg-background-dark sm:bg-card'
                                    placeholder={t('sec.mvr')}
                                    {...field}
                                    type='number'
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name='motorVehicleAge'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.mva')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    classLabel='bg-background-dark sm:bg-card'
                                    placeholder={t('sec.mva')}
                                    {...field}
                                    inputMode={'numeric'}
                                    type='number'
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name='motorVehicleOriginalCost'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.oc')}(BWP)
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name='motorVehicleMarketValue'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.om')} (BWP)
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name='motorVehicleEstimatedForcedSaleVal'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.efs')}(BWP)
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name='motorVehicleAmountEncumb'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.ae')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: t('sec.bg'),
                      fields: ['valueOfGurantee'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='valueOfGurantee'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.vg')} (BWP)
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: 'Any Other Guarantee',
                      fields: ['valueOfOtherGurantee'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='valueOfOtherGurantee'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.vg')} (BWP){' '}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: t('sec.lip'),
                      fields: ['sumAssured'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='sumAssured'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.sac')}(BWP)
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: t('sec.ofd'),
                      fields: ['fixedDeposit'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='fixedDeposit'
                            render={({ field }) => (
                              <FormItem className='flex flex-col mb-3'>
                                <FormLabel className='font-redHat'>{t('sec.fd')} (BWP)</FormLabel>
                                <FormControl>
                                  <div className='relative'>
                                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                      <Text size='bodyBold' className=''>
                                        BWP
                                      </Text>
                                    </div>
                                    <Input
                                      id='currency'
                                      inputMode='decimal'
                                      type='number'
                                      min={0}
                                      step={0.01}
                                      placeholder='0.00'
                                      className='pl-14'
                                      {...field}
                                    />
                                  </div>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: 'Basis  Of Your Estimation(As per stated security above)',
                      fields: ['estimations'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='estimations'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('sec.bas')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    classLabel='bg-background-dark sm:bg-card'
                                    placeholder={t('sec.bas')}
                                    {...field}
                                    type='text'
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    }
                  ]}
                />
              </div>
            </div>
          )}
          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}
