import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { NotFound } from '../../../components/Errors/NotFound'
import { useAtom } from 'jotai'
import { farmerType } from '../components/stepper/stores'
import { LoanApplicationFormCompany } from '../components/stepper/company/LoanApplicationFormCompany'
import { Box, TabView } from '../../../components/App/Layout'
import { Button, Text } from '../../../components/ui'
import { useTranslation } from 'react-i18next'
import { LoanApplicationForm } from '../components/stepper/individual/LoanApplicationForm'

export const LoanApplication: React.FC = () => {
  const { farmer } = useFarmerStore()
  const flags = useFlags()
  const ldClient = useLDClient()
  const [famerType] = useAtom(farmerType)
  const { t } = useTranslation('loanManagement')

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.loan === false || farmer?.class_id !== 'TAXPAYER' || farmer?.class_id == null) {
    return <NotFound />
  }

  const saveAndExist = (): void => {
    // save and exit logic
  }

  return (
    <Box>
      <TabView>
        <div className={'flex items-center justify-between p-4'}>
          <div className='flex flex-col sm:flex-row items-start md:items-center'>
            <Text size={'medium'}>{t('home.loanApp')} - &nbsp;</Text>
            {famerType?.farmerType === undefined ? (
              <Text size={'body'}>{t('home.application')}</Text>
            ) : (
              <Text size={'body'} className=''>
                {famerType?.farmerType} Farmer
              </Text>
            )}
          </div>

          <Button variant='secondary' onClick={saveAndExist} size={'xs'} className='w-32' disabled>
            {t('home.save')}
          </Button>
        </div>

        {farmer?.farmer_type === 1 ? (
          <LoanApplicationForm />
        ) : (
          farmer?.farmer_type === 2 && <LoanApplicationFormCompany />
        )}
      </TabView>
    </Box>
  )
}
