import { create } from 'zustand'
import {
  type Adjuvants,
  type Agrochemicals,
  type ApplicationStages,
  type Chemicals,
  type ControlMethods,
  type CroppingPlanStaticData,
  type Crops,
  type Fertilizers,
  type Fungicides,
  type Herbicides,
  type Pesticides,
  type Seeds,
  type Services
} from '../types/static'
import {
  type Implement,
  type PoweredImplement,
  type StaticInvData,
  type Tractor
} from '../features/farmer/inventory/types'
import { type Bank, type BankInformation, type BankNames } from '../types'

interface UseStaticDataStore {
  seeds: Seeds[] | null
  tractors: Tractor[] | null
  banksNames: BankNames[] | null
  setBanksNames: (banksNames: BankNames[] | null) => void
  banksInformation: BankInformation[] | null
  setBanksInformation: (banks: BankInformation[] | null) => void
  setTractors: (tractors: Tractor[] | null) => void
  nonPoweredImplements: Implement[] | null
  setNonPoweredImplements: (imps: Implement[] | null) => void
  poweredImplements: PoweredImplement[] | null
  setPoweredImplements: (imps: PoweredImplement[] | null) => void
  setSeeds: (seeds: Seeds[] | null) => void
  services: Services[] | null
  setServices: (services: Services[] | null) => void
  chemicals: Chemicals[] | null
  setChemicals: (chemicals: Chemicals[] | null) => void
  fertilizers: Fertilizers[] | null
  setFertilizers: (fertilizers: Fertilizers[] | null) => void
  crops: Crops[] | null
  setCrops: (crops: Crops[] | null) => void
  fungicides: Fungicides[] | null
  setFungicides: (fungicides: Fungicides[] | null) => void
  herbicides: Herbicides[] | null
  setHerbicides: (herbicides: Herbicides[] | null) => void
  pesticides: Pesticides[] | null
  setPesticides: (pesticides: Pesticides[] | null) => void
  adjuvants: Adjuvants[] | null
  setAdjuvants: (adjuvants: Adjuvants[] | null) => void
  applicationStages: ApplicationStages[] | null
  setApplicationStages: (applicationStages: ApplicationStages[] | null) => void
  controlMethods: ControlMethods[] | null
  setControlMethods: (controlMethods: ControlMethods[] | null) => void
  setStaticData: (
    staticData?: CroppingPlanStaticData,
    agro?: Agrochemicals,
    invData?: StaticInvData,
    banks?: Bank
  ) => void
}

export const useStaticDataStore = create<UseStaticDataStore>()((set) => ({
  tractors: null,
  nonPoweredImplements: null,
  poweredImplements: null,
  seeds: null,
  services: null,
  chemicals: null,
  fertilizers: null,
  crops: null,
  adjuvants: null,
  applicationStages: null,
  controlMethods: null,
  fungicides: null,
  herbicides: null,
  pesticides: null,
  banksNames: null,
  banksInformation: null,
  setBanksInformation: (banks: BankInformation[] | null) => {
    set((state) => ({ ...state, banks }))
  },
  setBanksNames: (banks: BankNames[] | null) => {
    set((state) => ({ ...state, banks }))
  },
  setTractors: (tractors: Tractor[] | null) => {
    set((state) => ({ ...state, tractors }))
  },
  setNonPoweredImplements: (imps: Implement[] | null) => {
    set((state) => ({ ...state, nonPoweredImplements: imps }))
  },
  setPoweredImplements: (imps: PoweredImplement[] | null) => {
    set((state) => ({ ...state, poweredImplements: imps }))
  },
  setSeeds: (seeds: Seeds[] | null) => {
    set((state) => ({ ...state, seeds }))
  },
  setServices: (services: Services[] | null) => {
    set((state) => ({ ...state, services }))
  },
  setChemicals: (chemicals: Chemicals[] | null) => {
    set((state) => ({ ...state, chemicals }))
  },
  setFertilizers: (fertilizers: Fertilizers[] | null) => {
    set((state) => ({ ...state, fertilizers }))
  },
  setCrops: (crops: Crops[] | null) => {
    set((state) => ({ ...state, crops }))
  },
  setFungicides: (fungicides: Fungicides[] | null) => {
    set((state) => ({ ...state, fungicides }))
  },
  setHerbicides: (herbicides: Herbicides[] | null) => {
    set((state) => ({ ...state, herbicides }))
  },
  setPesticides: (pesticides: Pesticides[] | null) => {
    set((state) => ({ ...state, pesticides }))
  },
  setAdjuvants: (adjuvants: Adjuvants[] | null) => {
    set((state) => ({ ...state, adjuvants }))
  },
  setApplicationStages: (applicationStages: ApplicationStages[] | null) => {
    set((state) => ({ ...state, applicationStages }))
  },
  setControlMethods: (controlMethods: ControlMethods[] | null) => {
    set((state) => ({ ...state, controlMethods }))
  },
  setStaticData: (
    staticData?: CroppingPlanStaticData,
    agro?: Agrochemicals,
    invData?: StaticInvData,
    banks?: Bank
  ) => {
    set((state) => ({
      ...state,
      seeds: staticData?.seeds,
      services: staticData?.services,
      chemicals: staticData?.chemicals,
      fertilizers: staticData?.npk_fertilizers,
      crops: staticData?.crops,
      fungicides: agro?.fungicides,
      herbicides: agro?.herbicides,
      pesticides: agro?.pesticides,
      adjuvants: agro?.adjuvants,
      applicationStages: agro?.application_stages,
      controlMethods: agro?.control_methods,
      poweredImplements: invData?.powered_implements,
      nonPoweredImplements: invData?.implements,
      tractors: invData?.tractors,
      banksInformation: banks?.bank_information,
      banksNames: banks?.banks
    }))
  }
}))
